import { Box, Drawer, Stack } from '@mui/material'
import React from 'react'
import SingleMessageInput from './templets/SingleMessageInput'
// import TextMsg from './templets/TextMsg'
// import TextImage from './templets/TextImage'
import AskQuestion from './templets/AskQuestion'

const TempletsComp = ({ data, states, setStates }) => {
    return (
        <Drawer
            key="4"
            onClose={() => setStates({ ...states, drawer: false })}
            anchor='right'
            open={states.drawer}>
            <Box bgcolor={'white'} overflow={'auto'} p={2} height="100vh" width={300}>
                <Stack direction={'column'} spacing={2}>
                    <AskQuestion data={data} states={states} setStates={setStates} />
                    {/* <TextMsg data={data} states={states} setStates={setStates} />
                    <TextImage data={data} states={states} setStates={setStates} /> */}
                </Stack>
            </Box>
        </Drawer>
    )
}

export default TempletsComp