import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { DeleteOutline, ListAlt } from '@mui/icons-material'
import { FlowContext } from '../../../context/FlowContext'

const CreateLIstMsg = ({ data, state, setState }) => {
    const FLOW = React.useContext(FlowContext)
    const [one, setOne] = React.useState({
        options: []
    })
    const [list, setList] = React.useState({
        btnText: "Menu Here",
        sections: []
    })

    function extractTitlesFromRows(data) {
        const titlesArray = [];

        if (!Array.isArray(data)) {
            return titlesArray;
        }

        data.forEach(item => {
            if (item && item.rows && Array.isArray(item.rows)) {
                item.rows.forEach(row => {
                    if (row && row.title) {
                        titlesArray.push(row.title);
                    }
                });
            }
        });

        return titlesArray;
    }
    return (
        <div>
            <Stack spacing={2} direction={'column'}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <ListAlt sx={{ color: 'gray' }} />
                    <Typography variant='h6' fontWeight={600} >{data.listMessage}</Typography>
                </Stack>
                <TextFieldNew
                    label={data.header}
                    value={list.header}
                    helperText={data.max60}
                    onChange={(e) => {
                        const inputText = e.target.value
                        if (inputText.length <= 60) {
                            setList({ ...list, header: e.target.value })
                        } else return
                    }}
                />
                <TextFieldNew
                    label={data.body}
                    multiline
                    rows={4}
                    value={list.body}
                    helperText={data.max1024}
                    onChange={(e) => {
                        const inputText = e.target.value
                        if (inputText.length <= 1024) {
                            setList({ ...list, body: e.target.value })
                        } else return
                    }}
                />

                <TextFieldNew
                    label={data.footer}
                    value={list.footer}
                    helperText={data.max60}
                    onChange={(e) => {
                        const inputText = e.target.value
                        if (inputText.length <= 60) {
                            setList({ ...list, footer: e.target.value })
                        } else return
                    }}
                />

                <TextFieldNew
                    label={data.btnText}
                    value={list.btnText}
                    helperText={data.max20}
                    onChange={(e) => {
                        const inputText = e.target.value
                        if (inputText.length <= 20) {
                            setList({ ...list, btnText: e.target.value })
                        } else return
                    }}
                />

                {list.sections?.map((i, key) => {
                    return (
                        <Box key={key} bgcolor={'action.hover'} p={1} borderRadius={2}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant='body2' fontWeight={500} >{i?.title}</Typography>
                                <IconButton
                                    onClick={() => {
                                        const newSection = list.sections?.filter(ii => ii !== i)
                                        setList({ ...list, sections: newSection })
                                    }}
                                    color='error' >
                                    <DeleteOutline sx={{ height: 20, width: 20 }} />
                                </IconButton>
                            </Stack>
                            {i?.rows?.map((item) => {
                                return (
                                    <Chip sx={{ mb: 1, mr: 1, mt: 1 }} label={item.title} size='small' />
                                )
                            })}
                        </Box>
                    )
                })}

                <Typography color={'gray'} variant='caption' >{data.addSection}</Typography>

                <Box bgcolor={'action.hover'} p={1} borderRadius={2}>
                    <Stack direction={'column'} spacing={2}>
                        <TextFieldNew
                            helperText={data.max24}
                            onChange={(e) => {
                                const inputText = e.target.value
                                if (inputText.length <= 24) {
                                    setOne({ ...one, title: e.target.value })
                                } else return
                            }}
                            value={one.title}
                            fullWidth
                            label={data.enterTitle}
                        />

                        {one.options?.map((i, key) => {
                            return (
                                <Chip
                                    size='small'
                                    onDelete={() => {
                                        const newOne = one.options?.filter(ii => ii !== i)
                                        setOne({ ...one, options: newOne })
                                    }}
                                    sx={{ m: 1 }} label={i} key={key} />
                            )
                        })}

                        <TextFieldNew
                            onChange={(e) => {
                                const inputText = e.target.value
                                if (inputText.length <= 24) {
                                    setOne({ ...one, option1: e.target.value })
                                } else return
                            }}
                            fullWidth
                            helperText={data.max24}
                            value={one.option1}
                            endIcon={<IconButton
                                onClick={() => {
                                    setOne({ ...one, options: [...one.options, one.option1], option1: "", })
                                }}
                                disabled={one.option1 ? false : true} >
                                <Typography variant='caption' >{data.addOption}</Typography>
                            </IconButton>}
                            label="Enter option"
                        />

                        <Button
                            onClick={() => {
                                const newSection = {
                                    title: one.title,
                                    rows: one.options.map((i, key) => ({
                                        id: key + Date.now(),
                                        title: i,
                                        description: ""
                                    }))
                                };
                                setList({ ...list, sections: [...list.sections, newSection] });
                                setOne({ options: [], title: "" });
                            }}
                            disabled={one.options.length < 1 || !one.title ? true : false}
                            size='small' variant='contained'
                            sx={{
                                textTransform: 'none',
                                borderRadius: 2,
                                boxShadow: 0
                            }} >{data.submitSection}</Button>
                    </Stack>
                </Box>

                <Button
                    onClick={() => {
                        FLOW.setNodes([
                            ...FLOW.nodes || [],
                            {
                                id: Date.now().toString(),
                                data: { data: "data", source: false, state },
                                position: {
                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                },
                                type: 'AskQuestionMap',
                                state,
                                nodeType: "QUESTION-LIST",
                                keyword: extractTitlesFromRows(list?.sections),
                                msgContent: {
                                    "type": "interactive",
                                    "interactive": {
                                        "type": "list",
                                        "header": {
                                            "type": "text",
                                            "text": list.header
                                        },
                                        "body": {
                                            "text": list.body
                                        },
                                        "footer": {
                                            "text": list.footer
                                        },
                                        "action": {
                                            "button": list.btnText,
                                            "sections": list.sections
                                        }
                                    }
                                }
                            }
                        ]);
                        setState({ ...state, text: "", dialog: false })
                    }}
                    disabled={list.header && list.body && list.footer && list.btnText && list.sections?.length > 0 ? false : true}
                    fullWidth
                    sx={{ textTransform: 'none', borderRadius: 2, boxShadow: 0 }}
                    variant='contained'
                    size='small'  >{data.addList}</Button>
            </Stack>
        </div>
    )
}

export default CreateLIstMsg