import { Box, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import Sendtext from './coversational/Sendtext';
import HowTo from './coversational/HowTo';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const sendText = `{
    "to": "18876656789",
    "type": "text",
    "text": {
        "preview_url": false,
        "body": "text-message-content"
    }
}`;

const sendMedia = `{
    "to": "18876656789",
    "type": "image",
    "image": {
        "link": "http(s)://image-url"
    }
}`;

const sendAudio = `{
    "to": "18876656789",
    "type": "audio",
    "audio": {
        "link": "http(s)://audio-url"
    }
}`;

const sendDoc = `{
    "to": "18876656789",
    "type": "document",
    "document": {
        "link": "<http(s)://document-url>",
        "caption": "<DOCUMENT_CAPTION_TEXT>"
    }
}`;

const sendVid = `{
    "to": "18876656789",
    "type": "video",
    "video": {
        "link": "<http(s)://video-url>",
        "caption": "<VIDEO_CAPTION_TEXT>"
    }
}`;

const sendList = `{
    "to": "18876656789",
    "type": "interactive",
    "interactive": {
        "type": "list",
        "header": {
            "type": "text",
            "text": "<HEADER_TEXT>"
        },
        "body": {
            "text": "<BODY_TEXT>"
        },
        "footer": {
            "text": "<FOOTER_TEXT>"
        },
        "action": {
            "button": "<BUTTON_TEXT>",
            "sections": [
                {
                    "title": "<LIST_SECTION_1_TITLE>",
                    "rows": [
                        {
                            "id": "<LIST_SECTION_1_ROW_1_ID>",
                            "title": "<SECTION_1_ROW_1_TITLE>",
                            "description": "<SECTION_1_ROW_1_DESC>"
                        },
                        {
                            "id": "<LIST_SECTION_1_ROW_2_ID>",
                            "title": "<SECTION_1_ROW_2_TITLE>",
                            "description": "<SECTION_1_ROW_2_DESC>"
                        }
                    ]
                },
                {
                    "title": "<LIST_SECTION_2_TITLE>",
                    "rows": [
                        {
                            "id": "<LIST_SECTION_2_ROW_1_ID>",
                            "title": "<SECTION_2_ROW_1_TITLE>",
                            "description": "<SECTION_2_ROW_1_DESC>"
                        },
                        {
                            "id": "<LIST_SECTION_2_ROW_2_ID>",
                            "title": "<SECTION_2_ROW_2_TITLE>",
                            "description": "<SECTION_2_ROW_2_DESC>"
                        }
                    ]
                }
            ]
        }
    }
}`

const sendReply = `{
    "to": "18876656789",
    "type": "interactive",
    "interactive": {
        "type": "button",
        "body": {
            "text": "<BUTTON_TEXT>"
        },
        "action": {
            "buttons": [
                {
                    "type": "reply",
                    "reply": {
                        "id": "<UNIQUE_BUTTON_ID_1>",
                        "title": "<BUTTON_TITLE_1>"
                    }
                },
                {
                    "type": "reply",
                    "reply": {
                        "id": "<UNIQUE_BUTTON_ID_2>",
                        "title": "<BUTTON_TITLE_2>"
                    }
                }
            ]
        }
    }
}`

const ConversationalApi = ({ hitAxios, data }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={
                        <Typography fontWeight={600} >
                            {data.howToRequest}
                        </Typography>
                    } {...a11yProps(0)} />
                    <Tab label={data.textMsg} {...a11yProps(1)} />
                    <Tab label={data.sendMediaMessage} {...a11yProps(2)} />
                    <Tab label={data.sendAudio} {...a11yProps(3)} />
                    <Tab label={data.sendDoc} {...a11yProps(4)} />
                    <Tab label={data.sendVideo} {...a11yProps(5)} />
                    <Tab label={data.sendList} {...a11yProps(6)} />
                    <Tab label={data.sendReplyButton} {...a11yProps(7)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <HowTo
                    data={data}
                    hitAxios={hitAxios}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendText}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendMedia}
                    text={data.supMedia}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendAudio}
                    text={data.supAudio}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendDoc}
                    text={data.supDoc}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={5}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendVid}
                    text={data.supVideo}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={6}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendList}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={7}>
                <Sendtext
                    data={data}
                    hitAxios={hitAxios}
                    code={sendReply}
                />
            </CustomTabPanel>
        </div>
    )
}

export default ConversationalApi