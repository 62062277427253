import React from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Container, Divider, Grid, LinearProgress, Typography, Box, Stack, CardMedia } from '@mui/material'
import Header from '../components/Header'
import { TranslateContext } from '../../context/TranslateContext'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet";

const ViewPage = (props) => {
    const slug = props.match.params.slug
    const [page, setPage] = React.useState("")
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);


    async function getPage() {
        const res = await hitAxios({
            path: "/api/admin/get_page_slug",
            post: true,
            admin: true,
            obj: { slug: slug }
        })
        if (!res.data.page) {
            setPage("NOPAGE")
        } else {
            setPage(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!slug) {
            return
        }
        getPage()
    }, [slug])


    return (
        <div>
            {page ?
                page === 'NOPAGE' ?
                    <Grid container minHeight={'100vh'} alignItems='center' justifyContent={'center'}>
                        <Grid item>
                            <Typography>No Page Found</Typography>
                        </Grid>
                    </Grid> :
                    <>
                        <Header
                            screenWidth={screenWidth}
                            data={data} />

                        <Container sx={{ minHeight: '100vh', pt: 4 }} maxWidth='lg'>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title >{page.title}</title>
                                {/* <meta name="description" content={page.meta} /> */}
                                <link rel="canonical" href={window.location.hostname} />
                            </Helmet>

                            <Stack direction={'column'} spacing={1} alignItems={'center'}>
                                {page?.image &&
                                    <CardMedia
                                        sx={{ height: "100%", width: 150 }}
                                        src={`/media/${page?.image}`}
                                        component={'img'}
                                    />}
                                <Typography fontWeight={'bold'} align='center' variant='h3' >{page.title}</Typography>
                            </Stack>

                            <Box p={1}>
                                <Divider variant='middle' />
                            </Box>
                            <Typography
                                sx={{ display: "inline-block" }}
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: page.content
                                }}
                            ></Typography>

                        </Container>

                        <Footer
                            screenWidth={screenWidth}
                            data={data} />

                    </> : <LinearProgress />}
        </div>
    )
}

export default ViewPage