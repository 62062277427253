import { Add, AddOutlined, ContactPage, EditNote, EditOutlined, QuickreplyOutlined } from '@mui/icons-material'
import { Box, Button, CardMedia, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ButtonComp from '../../context/ButtonComp'
import moment from 'moment'
import CloseBtn from '../../context/CloseBtn'
import randomstring from 'randomstring'

const FlowList = ({ screenWidth, data, state, setState, chatFlow, hitAxios, getFlows, FLOW }) => {

    async function delFlow(id, flowId) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/chat_flow/del_flow",
                post: true,
                admin: false,
                obj: { id, flowId }
            })
            if (res.data.success) {
                getFlows()
            }
        }
    }


    async function editFlow(flowId, title) {
        const res = await hitAxios({
            path: "/api/chat_flow/get_by_flow_id",
            post: true,
            admin: false,
            obj: { flowId }
        })
        if (res.data.success) {
            if (res.data.nodes?.length > 0 || res.data.edges?.length > 0) {
                FLOW.setNodes(res.data.nodes)
                FLOW.setEdges(res.data.edges)
                FLOW.setData({ ...FLOW.data, title, flowId })
            }
        }
    }

    return (
        <div>
            {screenWidth > 899 ? <Box width={250} minHeight={'92vh'} bgcolor={'white'}>
                <Box pr={4} pl={4} pt={4}>
                    <Stack alignItems={'center'} direction={'column'} spacing={3}>
                        <CardMedia
                            component={'img'}
                            sx={{ height: "100%", width: 250 }}
                            src='/assets/chatbot_title.svg'
                        />

                        <ButtonComp
                            onClick={() => {
                                const flowId = randomstring.generate()
                                FLOW.setNodes([])
                                FLOW.setEdges([])
                                FLOW.setData({ ...FLOW.data, title: "Untitled", flowId })
                            }}
                            startIcon={<AddOutlined />}
                            title={'Add New'} />

                        <Typography align='center' color={'gray'} >{data.yourchatFlows}</Typography>

                    </Stack>
                </Box>

                <Box p={2} >
                    <Divider />
                </Box>

                <Box height={'100%'} overflow={'auto'}>
                    <Stack pr={2} pl={2} spacing={2} direction={'column'} >
                        {chatFlow.map((i, key) => {
                            return (
                                <Stack key={key} justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                                    <Stack alignItems={'flex-start'} direction={'row'} spacing={1}>
                                        <Tooltip title={data.updateFlow} >
                                            <IconButton
                                                size='small'
                                                onClick={() => editFlow(i?.flow_id, i?.title)}
                                                color='info' >
                                                <EditNote sx={{ height: 20, width: 20 }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Stack direction={'column'}>
                                            <Typography variant='body2' >{i?.title}</Typography>
                                            <Typography variant='caption' color={'gray'} >{moment(i?.createdAt).fromNow()}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Tooltip title={data.deleteThis} >
                                        <Box>
                                            <CloseBtn
                                                onClick={() => delFlow(i.id, i.flow_id)}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Box>

            </Box> :
                <Drawer key='3' onClose={() => setState({ ...state, drawer: false })} open={state.drawer && screenWidth < 899}>
                    <Box pr={4} pl={4} pt={4}>
                        <Stack alignItems={'center'} direction={'column'} spacing={3}>
                            <CardMedia
                                component={'img'}
                                sx={{ height: "100%", width: 250 }}
                                src='/assets/chatbot_title.svg'
                            />
                            <ButtonComp
                                onClick={() => {
                                    const flowId = randomstring.generate()
                                    const initialNodes = [
                                        {
                                            id: '1',
                                            data: { data: "data", source: true },
                                            position: { x: 100, y: 100 },
                                            type: 'SingleMessageInput',
                                        },
                                    ];
                                    FLOW.setNodes(initialNodes)
                                    FLOW.setEdges([])
                                    FLOW.setData({ ...FLOW.data, title: "Untitled", flowId })
                                }}
                                startIcon={<AddOutlined />}
                                title={'Add New'} />

                            <Typography align='center' color={'gray'} >{data.yourchatFlows}</Typography>

                        </Stack>
                    </Box>

                    <Box p={2} >
                        <Divider />
                    </Box>

                    <Box height={'100%'} overflow={'auto'}>
                        <Stack pr={2} pl={2} spacing={2} direction={'column'} >
                            {chatFlow.map((i, key) => {
                                return (
                                    <Stack key={key} justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                                        <Stack alignItems={'flex-start'} direction={'row'} spacing={1}>
                                            <Tooltip title={data.updateFlow} >
                                                <IconButton
                                                    size='small'
                                                    onClick={() => editFlow(i?.flow_id, i?.title)}
                                                    color='info' >
                                                    <EditNote sx={{ height: 18, width: 18 }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Stack direction={'column'}>
                                                <Typography variant='body2' >{i?.title}</Typography>
                                                <Typography variant='caption' color={'gray'} >{moment(i?.createdAt).fromNow()}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Tooltip title={data.deleteThis} >
                                            <Box>
                                                <CloseBtn
                                                    small={true}
                                                    onClick={() => delFlow(i.id, i.flow_id)}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Box>
                </Drawer>}
        </div>
    )
}

export default FlowList