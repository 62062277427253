import { QuestionAnswerOutlined } from '@mui/icons-material'
import { Box, CardActionArea, Stack, Typography } from '@mui/material'
import React from 'react'

const SideBar = ({ data, screenWidth, menu, setMenu }) => {
    return (
        <div>
            {screenWidth > 899 &&
                <Box
                    width={250}
                    minHeight={'92vh'}
                    height={'100%'}
                    bgcolor={'white'}>
                    <Box p={1} >
                        <Stack direction={'column'} spacing={2}>
                            <CardActionArea
                                onClick={() => setMenu({ ...menu, selected: "CONVERSATION_API" })}
                                sx={{ p: 1.3, borderRadius: 2, bgcolor: menu.selected === "CONVERSATION_API" ? "action.hover" : "" }} >
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <QuestionAnswerOutlined sx={{ color: "gray", height: 20, width: 20 }} />
                                    <Typography
                                        sx={{
                                            color: (t) => menu.selected === "CONVERSATION_API" ? t.palette.primary.main : ""
                                        }}
                                        variant='body2'
                                        fontWeight={600}
                                    >{data.conversationalAPI}</Typography>
                                </Stack>
                            </CardActionArea>

                        </Stack>
                    </Box>

                </Box>}
        </div>
    )
}

export default SideBar