import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Divider, Stack, Typography } from '@mui/material';

const successCode = `{
    success: true,
    message: "Message sent successfully!"
}`

const unsuccessCode = `{
    success: false,
    message: "<REASON>"
}`

const bodyJson = `{
    messageObject: {...object}
}`

const HowTo = ({ data, hitAxios }) => {
    const codeStyle = {
        ...oneLight,
        fontSize: '10px', // Adjust the value as per your preference
    };


    return (
        <Box p={2}>

            <Stack direction={'column'} spacing={2}>
                <Stack direction="column">
                    <Stack direction="column" >
                        <Typography variant="subtitle1">
                            {data.how1}
                        </Typography>
                        <SyntaxHighlighter language="http" style={codeStyle}>
                            {`${window.location.protocol}//${window.location.host}/api/v1/send-message?token=API_KEYS`}
                        </SyntaxHighlighter>
                    </Stack>

                    <Stack direction="column" >
                        <Typography variant="subtitle1">
                            {data.how2}
                        </Typography>
                        <SyntaxHighlighter language="plaintext" style={codeStyle}>
                            {data.apiKeys}
                        </SyntaxHighlighter>
                    </Stack>

                    <Stack direction="column" >
                        <Typography variant="subtitle1">
                            {data.how3}
                        </Typography>

                        <SyntaxHighlighter language="js" style={codeStyle}>
                            {bodyJson}
                        </SyntaxHighlighter>
                    </Stack>
                </Stack>

                <Divider />

                <Stack direction={'column'} spacing={1}>
                    <Typography>
                        {data.successResponse}
                    </Typography>

                    <SyntaxHighlighter language="js" style={codeStyle}>
                        {successCode.trim()}
                    </SyntaxHighlighter>
                </Stack>

                <Stack direction={'column'} spacing={1}>
                    <Typography>
                        {data.usSuccessResponse}
                    </Typography>

                    <SyntaxHighlighter language="js" style={codeStyle}>
                        {unsuccessCode.trim()}
                    </SyntaxHighlighter>
                </Stack>
            </Stack>
        </Box>
    );
};

export default HowTo;
