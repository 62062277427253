import React from 'react'
import { TranslateContext } from '../../../context/TranslateContext';
import { FlowContext } from '../../../context/FlowContext';
import { Box, Button, CardMedia, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Handle, Position } from 'reactflow';
import { Article, AudioFile, CloudDownload, DeleteOutline, Image, ListAlt, OpenInNew, QuestionAnswer, RadioButtonChecked, Save, SaveAlt, VideoFile } from '@mui/icons-material';
import TextFieldNew from '../../../components/TextFieldNew';
import { GlobalContext } from '../../../context/GlobalContext'

const audioStyle = {
    width: '100%',
    margin: '0 auto',
    display: 'block',
};


const AskQuestionMap = ({ ...props }) => {
    const { data } = React.useContext(TranslateContext)
    const FLOW = React.useContext(FlowContext)
    const { hitAxios } = React.useContext(GlobalContext)

    function getObjectById(objectsArray, targetId) {
        const foundObject = objectsArray?.find(obj => obj?.id === targetId);
        return foundObject || {}; // Return foundObject if it exists, otherwise return an empty object
    }

    const state = getObjectById(FLOW.nodes, props.id)

    function extractTitlesFromRows(data) {
        const titlesArray = [];

        if (!Array.isArray(data)) {
            return titlesArray;
        }

        data.forEach(item => {
            if (item && item.rows && Array.isArray(item.rows)) {
                item.rows.forEach(row => {
                    if (row && row.title) {
                        titlesArray.push(row.title);
                    }
                });
            }
        });

        return titlesArray;
    }

    async function addTemplet(title, type, content) {
        const res = await hitAxios({
            path: "/api/templet/add_new",
            post: true,
            admin: false,
            obj: {
                title: title,
                type: type,
                content: content
            }
        })
    }

    return (
        <div  >
            {state.nodeType === "QUESTION-TEXT" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} maxWidth={300} >

                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#e95b69'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <QuestionAnswer sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.questionMsg}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>



                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "TEXT", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={2}>
                        <TextFieldNew
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            fontSize={12}
                            value={state?.state?.text}
                        />

                        {state?.state?.options?.map((i, key) => (
                            <Box key={key} sx={{ position: 'relative' }}>
                                <Chip label={i} sx={{ width: "100%" }} />
                                <Handle
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: '#e95b69',
                                        padding: 2
                                    }}
                                    id={i}
                                    position={Position.Right}
                                    type="source"
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box >
            }

            {state.nodeType === "QUESTION-BUTTON" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} maxWidth={300} >

                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#fe9932'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <RadioButtonChecked sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.questionBtn}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>



                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "BUTTON", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={2}>
                        <TextFieldNew
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            fontSize={12}
                            value={state?.state?.text}
                        />

                        {state?.state?.options?.map((i, key) => (
                            <Box key={key} sx={{ position: 'relative' }}>
                                {/* <Chip label={i} sx={{ width: "100%" }} /> */}
                                <Box key={key} bgcolor={'action.hover'} p={"5px 5px 5px 10px"} borderRadius={2}>
                                    <Typography variant='body2' >{i}</Typography>
                                </Box>
                                <Handle
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: '#fe9932',
                                        padding: 2
                                    }}
                                    id={i}
                                    position={Position.Right}
                                    type="source"
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box >
            }

            {state.nodeType === "QUESTION-LIST" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} maxWidth={300} >
                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#6c7fd7'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <ListAlt sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.questionlist}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>



                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "LIST", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={0.5}>
                        <Typography variant='caption' fontWeight={600}>{state?.msgContent?.interactive?.header?.text}</Typography>
                        <Typography variant='caption' fontWeight={400}>{state?.msgContent?.interactive?.body?.text}</Typography>
                        <Typography variant='caption' fontWeight={500} fontStyle={'italic'} >{state?.msgContent?.interactive?.footer?.text}</Typography>

                        <Button color='info' variant='contained' sx={{ textTransform: 'none', boxShadow: 0, fontWeight: 400 }} size='small'>{state?.msgContent?.interactive?.action?.button}</Button>

                        <Box p={1}>
                            <Typography color={'gray'} variant='caption' fontWeight={600} align='center' >{data.sectionsList}</Typography>
                        </Box>

                        {extractTitlesFromRows(state?.msgContent?.interactive?.action?.sections)?.map((i, key) => {
                            return (
                                <Box key={key} sx={{ position: 'relative' }}>
                                    <Box key={key} bgcolor={'action.hover'} p={"5px 5px 5px 10px"} borderRadius={2}>
                                        <Typography variant='body2' >{i}</Typography>
                                    </Box>
                                    <Handle
                                        style={{
                                            position: 'absolute',
                                            backgroundColor: '#6c7fd7',
                                            padding: 2
                                        }}
                                        id={i}
                                        position={Position.Right}
                                        type="source"
                                    />
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>}


            {state.nodeType === "QUESTION-IMAGE" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} maxWidth={300} >
                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#6495ed'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <Image sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.askByImage}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>



                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "IMAGE", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={2}>
                        <CardMedia src={state?.msgContent?.image?.link} component={'img'} />

                        <TextFieldNew
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            fontSize={12}
                            value={state?.msgContent?.image?.caption}
                        />

                        {state?.state?.options?.map((i, key) => (
                            <Box key={key} sx={{ position: 'relative' }}>
                                <Chip label={i} sx={{ width: "100%" }} />
                                <Handle
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: '#6495ed',
                                        padding: 2
                                    }}
                                    id={i}
                                    position={Position.Right}
                                    type="source"
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>}

            {state.nodeType === "QUESTION-VIDEO" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} maxWidth={300} >
                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#5E8DBF'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <VideoFile sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.videoMsg}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>

                                {console.log({ state })}

                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "VIDEO", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={2}>
                        <CardMedia src={state?.msgContent?.video?.link} controls component={'video'} />

                        <TextFieldNew
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            fontSize={12}
                            value={state?.msgContent?.video?.caption}
                        />

                        {state?.state?.options?.map((i, key) => (
                            <Box key={key} sx={{ position: 'relative' }}>
                                <Chip label={i} sx={{ width: "100%" }} />
                                <Handle
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: '#6495ed',
                                        padding: 2
                                    }}
                                    id={i}
                                    position={Position.Right}
                                    type="source"
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>}


            {state.nodeType === "QUESTION-AUDIO" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} width={300} >
                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#ff69b4'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <AudioFile sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.audrioMsg}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>



                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "AUDIO", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        </Stack>
                    </Box>

                    <Box p={1}>
                        <div>
                            <audio controls style={audioStyle}>
                                <source src={state?.msgContent?.audio?.link} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </Box>
                </Box>}

            {state.nodeType === "QUESTION-DOCUMENT" &&
                <Box bgcolor={'#F8F8F8'} boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"} width={300} >
                    <Handle id={props.id + "_target"} position={Position.Left} type="target" />

                    <Box bgcolor={'#20b2aa'} p={1}>
                        <Stack direction={'row'} spacing={5} alignItems={'center'} justifyContent={'space-between'}>

                            <Stack spacing={1} alignItems='center' direction={'row'} >
                                <Stack bgcolor={'rgba(255, 255, 255, 0.22)'} p={0.7} borderRadius={'50%'}>
                                    <Article sx={{ color: 'white', height: 16, width: 16 }} />
                                </Stack>
                                <Typography align='center' variant='caption' color={'white'} >{data.docMsg}</Typography>
                            </Stack>

                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <IconButton color='inherit' onClick={() => {
                                    const newOne = FLOW.nodes.filter((i) => i.id !== state?.id)
                                    FLOW.setNodes(newOne)
                                }} >
                                    <DeleteOutline sx={{ height: 16, width: 16 }} />
                                </IconButton>

                                {console.log({ state })}

                                <Tooltip title={data.saveAsTemplet} >
                                    <IconButton onClick={() => {
                                        const title = prompt(data.giveTemplettitle)
                                        addTemplet(title, "DOCUMENT", state?.msgContent)
                                    }} >
                                        <Save sx={{ height: 16, width: 16, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Box>

                    <Stack direction={'column'} p={2} spacing={2}>
                        <TextFieldNew
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            fontSize={12}
                            value={state?.msgContent?.document?.caption}
                        />

                        <Button startIcon={<OpenInNew />} size='small' sx={{ textTransform: 'none', boxShadow: 0 }}
                            variant='contained'
                            onClick={() => window.open(state?.msgContent?.document?.link)} >{data.download}</Button>

                        {state?.state?.options?.map((i, key) => (
                            <Box key={key} sx={{ position: 'relative' }}>
                                <Chip label={i} sx={{ width: "100%" }} />
                                <Handle
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: '#e95b69',
                                        padding: 2
                                    }}
                                    id={i}
                                    position={Position.Right}
                                    type="source"
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>}

        </div >
    )
}

export default AskQuestionMap