import { Box, Dialog, Stack } from '@mui/material'
import React from 'react'
import CloseBtn from '../../../../context/CloseBtn'
import EmojiPicker from 'emoji-picker-react';

const SendBottomEmoji = ({ data, state, setState }) => {
    return (
        <Dialog
            fullWidth
            PaperProps={{
                style: { borderRadius: 10 }
            }}
            key="7"
            open={state.emojiDialog}
            onClose={() => setState({ ...state, emojiDialog: false })}
        >
            <Box p={2} >
                <Stack mb={2} direction={'row'} justifyContent={'flex-end'}>
                    <CloseBtn
                        onClick={() => setState({ ...state, emojiDialog: false })}
                    />
                </Stack>
                <Box mt={2}>
                    <EmojiPicker style={{ width: "100%" }} onEmojiClick={(e) => {
                        setState({ ...state, text: `${state.text}${e.emoji}`, emojiDialog: false })
                    }} />
                </Box>
            </Box>
        </Dialog>
    )
}

export default SendBottomEmoji