import { Box, Button, Chip, Stack, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { Handle, Position } from 'reactflow'
import { GlobalContext } from '../../../context/GlobalContext'
import { TranslateContext } from '../../../context/TranslateContext'
import { Sms } from '@mui/icons-material'
import { FlowContext } from '../../../context/FlowContext'

const SingleMessageInput = ({ states, setStates, ...props }) => {
    const FLOW = React.useContext(FlowContext)
    const { data } = React.useContext(TranslateContext)
    const [msg, setMsg] = React.useState({
        type: "text",
        text: {
            "preview_url": false,
            "body": ""
        },
        unknown: false,
        isExact: true,
        id: props.id
    })

    function getObjectById(objectsArray, targetId) {
        const foundObject = objectsArray?.find(obj => obj?.id === targetId);
        return foundObject || {}; // Return foundObject if it exists, otherwise return an empty object
    }

    const state = getObjectById(FLOW.nodes, props.id)

    return (
        <Box
            boxShadow={"5px 10px 50px 0px rgba(0, 0, 0, 0.22)"}
            sx={{
                borderRadius: '5px', // Set border-radius for the entire box
                overflow: 'hidden', // Ensure the rounded corners are applied to child elements
            }}
        >
            <Box
                sx={{
                    borderTopRightRadius: '5px', // Set border-radius for the top corners
                    borderTopLeftRadius: '5px', // Set border-radius for the top corners
                    bgcolor: '#24A0C2',
                    p: 1,
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        <Sms sx={{ color: 'white', height: 16, width: 16 }} />
                        <Typography color={'white'} fontWeight={500} variant='caption' >{data?.textMessage}</Typography>
                    </Stack>

                    <Tooltip title={data?.checkThisForUnknown} >
                        <Switch
                            color='default'
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setMsg({ ...msg, unknown: e.target.checked, text: { ...msg.text, body: "{{OTHER_MESSAGES}}" } })
                                } else {
                                    setMsg({ ...msg, unknown: e.target.checked, text: { ...msg.text, body: "" } })
                                }
                            }}
                            checked={msg.unknown} size='small' />
                    </Tooltip>
                </Stack>
            </Box>

            <Box
                sx={{
                    borderBottomRightRadius: '5px', // Set border-radius for the bottom corners
                    borderBottomLeftRadius: '5px', // Set border-radius for the bottom corners
                    p: 1,
                    bgcolor: 'white',
                }}
            >
                <TextFieldNew
                    placeholder={msg.isExact ? data.hello : data?.helloHiHey}
                    disabled={msg.unknown}
                    value={state?.text?.body}
                    onChange={(e) => setMsg({ ...msg, text: { ...msg.text, body: e.target.value } })}
                    fullWidth
                    multiline
                    fontSize={14}
                    rows={3}
                />

                {!msg.unknown &&
                    <Stack mt={2} direction={'row'} spacing={1} >
                        <Chip
                            color='info'
                            onClick={() => setMsg({ ...msg, isExact: false })}
                            variant={msg.isExact === true ? 'outlined' : 'filled'} sx={{ width: '100%', fontSize: 12 }} label={data?.matching} />
                        <Chip
                            color='info'
                            onClick={() => setMsg({ ...msg, isExact: true })}
                            variant={msg.isExact === true ? 'filled' : 'outlined'} sx={{ width: '100%', fontSize: 12 }} label={data?.exactMatched} />
                    </Stack>}

                <Box mt={1} >
                    <Typography variant='caption' color={'gray'} >{data?.thisIsInputMsg}</Typography>
                </Box>

                <Handle
                    style={{
                        backgroundColor: '#24A0C2',
                        padding: 2
                    }}
                    // onConnect={(connection) => console.log({ connection })}
                    type='source'
                    position={Position.Right} />
            </Box>
        </Box>
    )
}

export default SingleMessageInput