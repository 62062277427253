import React from 'react';
import { Group, Paid, PermContactCalendar } from '@mui/icons-material'
import UserChart from './UserChart';
import { GlobalContext } from '../../context/GlobalContext';
import { TranslateContext } from '../../context/TranslateContext';
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

const Dashboard = () => {
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const CON = React.useContext(GlobalContext)
    const [users, setUsers] = React.useState([])
    async function getUsers() {
        const res = await hitAxios({
            path: "/api/admin/get_dashboard_for_user",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setUsers(res.data.data)
        }
    }

    React.useEffect(() => {
        getUsers()
    }, [])

    return (
        <div >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Box p={2} borderRadius={3} bgcolor={'action.hover'} minHeight={'60vh'}>
                        {CON.data.loading ?
                            <Stack height={'60vh'} >
                                {Array(7).fill(null).map((_, key) => (
                                    <Skeleton height={53} key={key} animation="wave" />
                                ))}
                            </Stack> :
                            users?.paid && users?.unpaid && users?.orders &&
                            <UserChart
                                data={data}
                                users={users} />
                        }
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                    <Box p={3} borderRadius={3} bgcolor={'action.hover'} minHeight={'20vh'}>
                        <Stack direction={'column'} spacing={2}>
                            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <Group sx={{ color: '#3198f6' }} />
                                    <Typography color={'#3198f6'}>{data.totlUsers}</Typography>
                                </Stack>

                                <Typography fontWeight={600} >
                                    {users?.userLength || "🧐"}
                                </Typography>
                            </Stack>
                            <Divider />

                            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <Paid sx={{ color: "#feb11a" }} />
                                    <Typography color={"#feb11a"} >{data.totalOrders}</Typography>
                                </Stack>

                                <Typography fontWeight={600} >
                                    {users?.orderLength || "🧐"}
                                </Typography>
                            </Stack>

                            <Divider />

                            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <PermContactCalendar />
                                    <Typography>{data.totalLeads}</Typography>
                                </Stack>

                                <Typography fontWeight={600} >
                                    {users?.contactLength || "🧐"}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
