import { Button, CardActionArea, CardMedia, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { GlobalContext } from '../../../context/GlobalContext'
import { FlowContext } from '../../../context/FlowContext'
import { AudioFile } from '@mui/icons-material'

const audioStyle = {
    width: '100%',
    margin: '0 auto',
    display: 'block',
};

const CreateAudio = ({ data, state, setState }) => {
    const FLOW = React.useContext(FlowContext)
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [img, setImg] = React.useState({
        caption: "",
        file: ""
    })

    async function returnUrl(media) {
        const fd = new FormData()
        fd.append('file', media)
        const res = await hitAxios({
            path: "/api/user/return_media_url",
            post: true,
            admin: false,
            obj: fd
        })
        if (res.data.success) {
            setImg({ ...img, url: res.data.url })
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <AudioFile sx={{ color: 'gray' }} />
                    <Typography variant='h6' fontWeight={600} >{data.audrioMsg}</Typography>
                </Stack>

                {CON.data.loading && <LinearProgress />}
                <CardActionArea component='label' sx={{ borderRadius: 2, bgcolor: '#ECECEC' }}>
                    <input
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 16 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                    alert(data.audioSizeAlert);
                                } else {
                                    returnUrl(selectedFile);
                                }
                            }
                        }}
                        type='file'
                        accept='.aac, .m4a, .amr, .mp3, .opus'
                        hidden
                    />
                    <Stack m={4} alignItems={'center'}>
                        <Typography variant='caption' color='gray' >{data.uploadAudio}</Typography>
                    </Stack>
                </CardActionArea>

                {img.url &&
                    <div>
                        <audio controls style={audioStyle}>
                            <source src={img.url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>}

                <Button
                    disabled={img.url ? false : true}
                    onClick={() => {
                        FLOW.setNodes([
                            ...FLOW.nodes || [],
                            {
                                id: Date.now().toString(),
                                data: { data: "data", source: false, state },
                                position: {
                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                },
                                type: 'AskQuestionMap',
                                state,
                                nodeType: "QUESTION-AUDIO",
                                keyword: [],
                                msgContent: {
                                    "type": "audio",
                                    "audio": {
                                        "link": img.url
                                    }
                                }
                            }
                        ]);
                        setState({ ...state, text: "", dialog: false })
                    }}
                    sx={{ textTransform: 'none', borderRadius: 2, boxShadow: 0 }} variant='contained' >{data.addThis}</Button>
            </Stack>
        </div>
    )
}

export default CreateAudio