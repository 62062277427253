import React from 'react';
import Chart from 'react-apexcharts';

const UserChart = ({ users, data }) => {
    const options = {
        chart: {
            id: 'basic-line-chart'
        },
        xaxis: {
            categories: users?.paid?.map((i) => i.month)
        }
    };

    const series = [{
        name: data.paidUsers,
        data: users?.paid?.map((i) => i.numberOfSignups)
    },
    {
        name: data.unpaidUsers,
        data: users?.unpaid?.map((i) => i.numberOfSignups)
    },
    {
        name: data.orders,
        data: users?.orders?.map((i) => i.numberOfOders)
    }];

    return (
        <Chart
            id='basic-line-chart'
            height={'100%'}
            width={'100%'}
            options={options} series={series} type="area" />
    );
};

export default UserChart;
