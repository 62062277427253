import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import { Box, Button, CardActionArea, CardMedia, Divider, Stack, Switch, Typography } from '@mui/material'
import { AddPhotoAlternate, SaveOutlined } from '@mui/icons-material'
import TextFieldNew from '../../components/TextFieldNew'

const AppConfig = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
    })

    async function updateWeb() {
        const fd = new FormData()
        fd.append('file', state.logo_image)
        fd.append('app_name', state.app_name)
        fd.append('custom_home', state.custom_home)
        fd.append('is_custom_home', state.is_custom_home)
        fd.append('meta_description', state.meta_description)
        fd.append('logo', state.logo)
        fd.append('currency_code', state.currency_code)
        fd.append('currency_symbol', state.currency_symbol)

        fd.append('home_page_tutorial', state.home_page_tutorial)
        fd.append('chatbot_screen_tutorial', state.chatbot_screen_tutorial)
        fd.append('broadcast_screen_tutorial', state.broadcast_screen_tutorial)

        const res = await hitAxios({
            path: "/api/web/update_web_config",
            post: true,
            admin: true,
            obj: fd
        })
    }

    async function getWeb() {
        const res = await hitAxios({
            path: "/api/admin/get_web_public",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setState({ ...state, ...res.data.data })
        }
    }

    React.useEffect(() => {
        getWeb()
    }, [])


    return (
        state?.id &&
        <div>
            <Box mt={2} >
                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={'bold'}
                            variant='h6'>{data.appConfig}</Typography>
                        <Typography
                            color={'gray'}
                            variant='caption'>{data.updateYourAppSettings}</Typography>
                    </Stack>
                </Stack>

                <Box mt={4}>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={4}>
                            <Stack spacing={2} alignItems='center' border={1} justifyContent={'center'} borderRadius={2} p={2}>
                                <Button variant='outlined' component="label">
                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        type="file"
                                        hidden
                                        onChange={(e) => setState({ ...state, logo_image: e.target.files[0] })}
                                    />
                                    {
                                        state.logo_image ? (
                                            <img src={URL.createObjectURL(state.logo_image)} style={{ height: 100, width: 100, borderRadius: 5 }} />
                                        ) : (
                                            state.logo ? (
                                                <img src={`/media/${state.logo}`} style={{ height: 100, width: 100, borderRadius: 5 }} />
                                            ) : <AddPhotoAlternate sx={{ height: 100, width: 100 }} />
                                        )
                                    }
                                </Button>
                                <Typography fontWeight={'bold'} color={state.color} >{data.logo}</Typography>

                            </Stack>
                        </Stack>

                        <Box bgcolor={'#e2f5fe'} borderRadius={2} p={2}>
                            <Stack direction={'column'} spacing={2}>
                                <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                                    <Typography>{data.allowCustomHome}</Typography>
                                    <Switch
                                        onChange={(e) => setState({ ...state, is_custom_home: e.target.checked ? 1 : 0 })}
                                        checked={state?.is_custom_home > 0 ? true : false}
                                    />
                                </Stack>

                                {state.is_custom_home > 0 &&
                                    <TextFieldNew
                                        onChange={(e) => setState({ ...state, custom_home: e.target.value })}
                                        value={state.custom_home}
                                        label={data.enterCustomHomeUrl}
                                    />}
                            </Stack>
                        </Box>

                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                fullWidth
                                onChange={(e) => setState({ ...state, app_name: e.target.value })}
                                value={state.app_name}
                                label={data.appName}
                            />

                            <TextFieldNew
                                fullWidth
                                onChange={(e) => setState({ ...state, currency_code: e.target.value })}
                                value={state.currency_code}
                                label={data.currencyCode}
                            />

                            <TextFieldNew
                                fullWidth
                                onChange={(e) => setState({ ...state, currency_symbol: e.target.value })}
                                value={state.currency_symbol}
                                label={data.currencySymbol}
                            />
                        </Stack>

                        <TextFieldNew
                            onChange={(e) => setState({ ...state, meta_description: e.target.value })}
                            value={state.meta_description}
                            multiline
                            rows={3}
                            label={data.seoDes}
                        />

                        <Divider />

                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                onChange={(e) => setState({ ...state, home_page_tutorial: e.target.value })}
                                value={state.home_page_tutorial}
                                helperText={data.youtubeLink}
                                label={data.frontPageVideo}
                                fullWidth
                            />
                            <TextFieldNew
                                onChange={(e) => setState({ ...state, chatbot_screen_tutorial: e.target.value })}
                                value={state.chatbot_screen_tutorial}
                                helperText={data.youtubeLink}
                                label={data.chatbotScreenVideo}
                                fullWidth
                            />
                            <TextFieldNew
                                onChange={(e) => setState({ ...state, broadcast_screen_tutorial: e.target.value })}
                                value={state.broadcast_screen_tutorial}
                                helperText={data.youtubeLink}
                                label={data.broadcastScreenVideo}
                                fullWidth
                            />
                        </Stack>

                        <Button
                            onClick={updateWeb}
                            startIcon={<SaveOutlined />}
                            sx={{ borderRadius: 2, boxShadow: 0 }}
                            variant='contained'
                        >
                            {data.submit}
                        </Button>

                    </Stack>
                </Box>
            </Box>
        </div>
    )
}

export default AppConfig