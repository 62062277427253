import { AddCircle, Article, AudioFile, Close, EditOutlined, Image, ListAlt, QuestionAnswer, RadioButtonChecked, VideoFile, WavingHand, WavingHandOutlined } from '@mui/icons-material'
import { Box, Button, CardActionArea, Chip, Dialog, Divider, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CloseBtn from '../../../context/CloseBtn'
import TextFieldNew from '../../../components/TextFieldNew'
import { FlowContext } from '../../../context/FlowContext'
import CreateLIstMsg from './CreateLIstMsg'
import CreateImage from './CreateImage'
import CreateAudio from './CreateAudio'
import CreateDoc from './CreateDoc'
import CreateVideo from './CreateVideo'

const AskQuestion = ({ data, states, setSatates }) => {
    const [forAll, setForAll] = React.useState({
        yes: false
    })
    const FLOW = React.useContext(FlowContext)
    const [file, setFile] = React.useState({
        file: "",
        url: ""
    })
    const [state, setState] = React.useState({
        dialog: false,
        dialogType: "TEXT",
        options: []
    })
    return (
        <Box bgcolor={'#F8F8F8'} >

            <Stack direction={'column'} spacing={2}>
                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "TEXT",
                    options: []
                })}
                    sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#e95b69'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700} >{data.AskQuestion}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.askQuestionWithMultipleVariable}</Typography>
                                </Stack>
                                <QuestionAnswer sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "IMAGE",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#6495ed'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700}>{data.askByImage}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.askByImgDes}</Typography>
                                </Stack>
                                <Image sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "AUDIO",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#ff69b4'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700}>{data.audrioMsg}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.audioMsgDes}</Typography>
                                </Stack>
                                <AudioFile sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "VIDEO",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#5E8DBF'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700}>{data.videoMsg}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.videoMsgDes}</Typography>
                                </Stack>
                                <VideoFile sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "DOCUMENT",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#20b2aa'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700}>{data.docMsg}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.docMsgDes}</Typography>
                                </Stack>
                                <Article sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "BUTTON",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#fe9932'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700} >{data.askQuesButton}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.askButtonDes}</Typography>
                                </Stack>
                                <RadioButtonChecked sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>

                <CardActionArea onClick={() => setState({
                    dialog: true,
                    dialogType: "LIST",
                    options: []
                })} sx={{ borderRadius: 3 }} >
                    <Box position={'relative'}>
                        <Box bgcolor={'#6c7fd7'} p={2} borderRadius={2}>
                            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                                <Stack spacing={1} direction={'column'}>
                                    <Typography color={'white'} fontWeight={700}>{data.askUsingList}</Typography>
                                    <Typography color={'white'} variant='body2' fontWeight={500} >{data.askListDes}</Typography>
                                </Stack>
                                <ListAlt sx={{ color: 'white' }} />
                            </Stack>
                        </Box>

                        <Box top={-20} left={-20} position={'absolute'} bgcolor={'rgba(255, 255, 255, 0.09)'} p={6} borderRadius={'50%'} />
                    </Box>
                </CardActionArea>
            </Stack>

            <Dialog
                fullWidth
                sx={{ backdropFilter: "blur(5px)" }}
                PaperProps={{
                    style: { borderRadius: 10 }
                }}
                open={state.dialog} onClose={() => setState({ ...state, dialog: false })} >
                <Box p={2}>
                    <Stack justifyContent={'flex-end'} alignItems={'center'} direction={'row'} spacing={6}>
                        <CloseBtn
                            onClick={() => setState({ ...state, dialog: false })}
                        />
                    </Stack>

                    <Divider />

                    {state.dialogType === "TEXT" &&
                        <Box mt={2}>
                            <Stack direction={'column'} spacing={2}>
                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                        <EditOutlined sx={{ color: 'gray' }} />
                                        <Typography variant='h6' fontWeight={600} >{data.simpleText}</Typography>
                                    </Stack>

                                    {FLOW?.nodes?.length < 1 &&
                                        <Tooltip title={data.checkForAllIncomingMessages} >
                                            <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                                <Typography variant='body2' fontWeight={600} >{data?.setForAll}</Typography>
                                                <Switch
                                                    onChange={(e) => {
                                                        setForAll({ ...forAll, yes: !forAll.yes })
                                                        setState({ ...state, options: ['{{OTHER_MSG}}'], text: "{{OTHER_MSG}}" })
                                                    }}
                                                    checked={forAll.yes} />
                                            </Stack>
                                        </Tooltip>}
                                </Stack>

                                <TextFieldNew
                                    value={state.text}
                                    disabled={forAll.yes}
                                    onChange={(e) => setState({ ...state, text: e.target.value })}
                                    placeholder="hello choose one color &#13;&#10;1:- Red &#13;&#10;2:- Green"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />

                                <Stack flexWrap={'wrap'} direction={'row'} >
                                    {state?.options?.map((i, key) => {
                                        return (
                                            <Chip
                                                sx={{ m: 1 }}
                                                onDelete={() => {
                                                    const newOne = state.options.filter((ii) => ii !== i)
                                                    setState({ ...state, options: newOne })
                                                }}
                                                label={i} key={key} />
                                        )
                                    })}
                                </Stack>

                                <TextFieldNew
                                    disabled={forAll.yes}
                                    placeholder={data.replyKey}
                                    value={state?.option1}
                                    onChange={(e) => setState({ ...state, option1: e.target.value })}
                                    endIcon={<IconButton disabled={state.option1 ? false : true} onClick={() => {
                                        setState({ ...state, options: [...state.options, state.option1], option1: "" })
                                    }} >
                                        <AddCircle />
                                    </IconButton>}
                                />

                                <Button
                                    startIcon={forAll.yes && <WavingHand />}
                                    onClick={() => {
                                        FLOW.setNodes([
                                            ...FLOW.nodes || [],
                                            {
                                                id: Date.now().toString(),
                                                data: { data: "data", source: false, state },
                                                position: {
                                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                                },
                                                type: 'AskQuestionMap',
                                                state,
                                                nodeType: "QUESTION-TEXT",
                                                keyword: state.options,
                                                msgContent: {
                                                    "type": "text",
                                                    "text": {
                                                        "preview_url": true,
                                                        "body": state.text
                                                    }
                                                }
                                            }
                                        ]);
                                        setState({ ...state, text: "", dialog: false })
                                    }}
                                    disabled={state.text && state.options.length > 0 ? false : true} variant='contained' sx={{ borderRadius: 2, boxShadow: 0, textTransform: 'none' }} >
                                    {data.addThis}
                                </Button>


                            </Stack>
                        </Box>}


                    {state.dialogType === "BUTTON" &&
                        <Box mt={2}>
                            <Stack direction={'column'} spacing={2}>
                                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                    <RadioButtonChecked sx={{ color: 'gray' }} />
                                    <Typography variant='h6' fontWeight={600} >{data.buttonMessage}</Typography>
                                </Stack>
                                <TextFieldNew
                                    helperText={data.max1024}
                                    onChange={(e) => {
                                        const inputText = e.target.value;
                                        if (inputText.length <= 1024) {
                                            setState({ ...state, text: e.target.value })
                                        } else return
                                    }}
                                    placeholder={data.yourMsg}
                                    fullWidth
                                    // label={data.questionMsg}
                                    multiline
                                    rows={4}
                                />

                                <Stack flexWrap={'wrap'} spacing={1} direction={'column'} >
                                    {state?.options?.map((i, key) => {
                                        return (
                                            <Box key={key} bgcolor={'action.hover'} p={"5px 5px 5px 10px"} borderRadius={2}>
                                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Typography variant='body2'>{i}</Typography>
                                                    <IconButton size='small' onClick={() => {
                                                        const newOne = state.options.filter((ii) => ii !== i)
                                                        setState({ ...state, options: newOne })
                                                    }} color='error' >
                                                        <Close sx={{ height: 20, width: 20 }} />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        )
                                    })}
                                </Stack>

                                {state.options.length <= 2 &&
                                    <TextFieldNew
                                        helperText={data.max20}
                                        onChange={(e) => {
                                            const inputText = e.target.value;

                                            if (inputText.length <= 20) {
                                                setState({ ...state, option1: inputText });
                                            }
                                        }}
                                        placeholder={data.buttonTitle}
                                        value={state?.option1}
                                        endIcon={
                                            <IconButton
                                                disabled={!state.option1 || state.option1.length > 20}
                                                onClick={() => {
                                                    setState({ ...state, options: [...state.options, state.option1], option1: "" });
                                                }}
                                            >
                                                <AddCircle />
                                            </IconButton>
                                        }
                                    />}

                                <Button
                                    onClick={() => {
                                        FLOW.setNodes([
                                            ...FLOW.nodes || [],
                                            {
                                                id: Date.now().toString(),
                                                data: { data: "data", source: false, state },
                                                position: {
                                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                                },
                                                type: 'AskQuestionMap',
                                                state,
                                                nodeType: "QUESTION-BUTTON",
                                                keyword: state.options,
                                                msgContent: {
                                                    "type": "interactive",
                                                    "interactive": {
                                                        "type": "button",
                                                        "body": {
                                                            "text": state.text
                                                        },
                                                        "action": {
                                                            buttons: state?.options?.map((i, key) => ({
                                                                type: 'reply',
                                                                reply: { id: key, title: i }
                                                            }))
                                                        }
                                                    }
                                                }
                                            }
                                        ]);
                                        setState({ ...state, text: "", dialog: false })
                                    }}
                                    disabled={state.text && state.options.length > 0 ? false : true} variant='contained' sx={{ borderRadius: 2, boxShadow: 0, textTransform: 'none' }} >
                                    {data.addThis}
                                </Button>
                            </Stack>
                        </Box>
                    }

                    {state.dialogType === "LIST" &&
                        <Box mt={2}>
                            <CreateLIstMsg data={data} state={state} setState={setState} />
                        </Box>
                    }

                    {state.dialogType === "IMAGE" &&
                        <Box mt={2}>
                            <CreateImage data={data} state={state} setState={setState} />
                        </Box>
                    }

                    {state.dialogType === "AUDIO" &&
                        <Box mt={2}>
                            <CreateAudio data={data} state={state} setState={setState} />
                        </Box>
                    }

                    {state.dialogType === "VIDEO" &&
                        <Box mt={2}>
                            <CreateVideo data={data} state={state} setState={setState} />
                        </Box>
                    }

                    {state.dialogType === "DOCUMENT" &&
                        <Box mt={2}>
                            <CreateDoc data={data} state={state} setState={setState} />
                        </Box>
                    }
                </Box>
            </Dialog>
        </Box>
    )
}

export default AskQuestion