import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import { Box, Stack, Chip } from '@mui/material';
import SideBar from './SideBar';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import LeftSide from './LeftSide';

const ApiAccess = () => {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const { hitAxios } = React.useContext(GlobalContext)
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        sideBar: false,
        addDialog: false
    })
    const [menu, setMenu] = React.useState({
        selected: "CONVERSATION_API"
    })

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    return (
        <div>
            <Stack direction={'row'}>

                <SideBar
                    setMenu={setMenu}
                    menu={menu}
                    state={state}
                    setState={setState}
                    data={data}
                    screenWidth={screenWidth}
                />


                <Box sx={{ width: screenWidth > 899 ? screenWidth - 250 : "100%" }}>

                    {screenWidth < 900 &&
                        <Box p={2} pr={2} pl={2} >
                            <Stack direction='row' spacing={2}>
                                <Chip
                                    icon={<QuestionAnswerOutlined sx={{ color: "gray", height: 20, width: 20 }} />}
                                    variant={menu.selected === "CONVERSATION_API" ? 'filled' : 'outlined'}
                                    onClick={() => setMenu({ ...menu, selected: "CONVERSATION_API" })}
                                    label={data.conversationalAPI}
                                />
                            </Stack>
                        </Box>
                    }

                    <LeftSide
                        setMenu={setMenu}
                        menu={menu}
                        state={state}
                        setState={setState}
                        data={data}
                        hitAxios={hitAxios}
                        screenWidth={screenWidth}
                    />
                </Box>

            </Stack>
        </div>
    )
}

export default ApiAccess