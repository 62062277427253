import { Box, Button, CardMedia, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import { Article, CallOutlined, CheckOutlined, Download, Image, OpenInNewOutlined, PlayCircleOutline, ReplyOutlined } from '@mui/icons-material'

const SideMobile = ({ data, setStates, actionBtn, quickReply, states, format }) => {

    return (
        <div>
            <Box
                overflow={'auto'}
                bgcolor={'#ece9de'}
                boxShadow={'0px 0px 50px 5px rgba(0, 0, 0, 0.25)'}
                borderRadius={6}
                border={5}
                borderColor={'white'}
                height={500}>

                <Box
                    sx={{ borderTopRightRadius: 20, borderTopLeftRadius: 20 }}
                    bgcolor={'#074e47'}
                    p={1} />

                <Box
                    bgcolor={'#055f54'}
                    p={2} />

                <Box
                    m={1}
                    borderRadius={2}
                    bgcolor={'white'} p={1.5}>

                    <Stack direction={'column'} spacing={1}>
                        {format === "TEXT" && <Typography variant='body2' fontWeight={500} >{states?.headerText}</Typography>}

                        {format === "MEDIA" && states.mediaType === "IMAGE" &&
                            (states.url ? (
                                <CardMedia height={150} src={states.url} component={'img'} />
                            ) : (
                                <Box borderRadius={2} p={6} bgcolor={'#E2E2E2'} >
                                    <Stack alignItems={'center'}>
                                        <CardMedia
                                            sx={{ height: 40, width: 40 }}
                                            src={`/assets/no_img.png`} component={'img'} />
                                    </Stack>
                                </Box>
                            ))
                        }

                        {format === "MEDIA" && states.mediaType === "VIDEO" &&
                            (states.url ? (
                                <CardMedia
                                    controls
                                    src={states.url}
                                    height={150} component={'video'} />
                            ) : (
                                <Box borderRadius={2} p={6} bgcolor={'#E2E2E2'} >
                                    <Stack alignItems={'center'}>
                                        <PlayCircleOutline
                                            sx={{ height: 40, width: 40, color: 'gray' }} />
                                    </Stack>
                                </Box>
                            ))
                        }

                        {format === "MEDIA" && states.mediaType === "DOCUMENT" &&
                            (states.url ? (
                                <Box borderRadius={2} p={6} bgcolor={'#E2E2E2'} >
                                    <Stack alignItems={'center'}>
                                        <CheckOutlined
                                            sx={{ height: 40, width: 40, color: (t) => t.palette.primary.main }} />
                                    </Stack>
                                </Box>
                            ) : (
                                <Box borderRadius={2} p={6} bgcolor={'#E2E2E2'} >
                                    <Stack alignItems={'center'}>
                                        <Article
                                            sx={{ height: 40, width: 40, color: 'gray' }} />
                                    </Stack>
                                </Box>
                            ))
                        }

                        {states.bodyMsg &&
                            <Typography variant='caption' >{states.bodyMsg}</Typography>}

                        {states.footerMsg &&
                            <Typography color={'gray'} variant='caption' >{states.footerMsg}</Typography>}

                        {quickReply.length > 0 &&
                            <>
                                <Divider />

                                <Stack direction={'column'} spacing={1}>
                                    {quickReply?.map((i, key) => {
                                        return (
                                            <Button
                                                startIcon={<ReplyOutlined />}
                                                sx={{ textTransform: 'none', borderRadius: 2 }}
                                                key={key} variant='outlined' size='small' >{i?.text}</Button>
                                        )
                                    })}
                                </Stack>
                            </>}


                        {actionBtn.length > 0 &&
                            <>
                                <Divider />

                                <Stack direction={'column'} spacing={1}>
                                    {actionBtn?.map((i, key) => {
                                        return (
                                            <Button
                                                startIcon={i?.type === "PHONE_NUMBER" ? <CallOutlined /> : <OpenInNewOutlined />}
                                                sx={{ textTransform: 'none', borderRadius: 2 }}
                                                key={key} variant='outlined' size='small' >{i?.text}</Button>
                                        )
                                    })}
                                </Stack>
                            </>}

                    </Stack>

                    <Stack mt={1} direction={'row'} justifyContent={'flex-end'}>
                        <Typography variant='caption' >{moment().format('hh:mmA')}</Typography>
                    </Stack>
                </Box>

            </Box>
        </div>
    )
}

export default SideMobile