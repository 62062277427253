import { Button } from '@mui/material';
import { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import TextFieldNew from './components/TextFieldNew';
import BtnMsg from './BtnMsg';

const nodeTypes = {
    BtnMsgNode: BtnMsg,
};

const initialNodes = [
    {
        id: '1',
        data: { label: 'Hello' },
        position: { x: 0, y: 0 },
        type: 'input',
    },
    {
        id: '2',
        type: 'BtnMsgNode',
        data: { onChange: "", color: "" },
        style: { border: '1px solid #777', padding: 10 },
        position: { x: 300, y: 50 },
    },
    {
        id: '3',
        data: { label: 'Chinnu' },
        position: { x: 150, y: 150 },
    },
];


const initialEdges = [

]

function ReactFlowComp() {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [],
    );

    return (
        <div style={{ height: '100vh' }}>
            <Button onClick={() => console.log({ edges, nodes })} >Hey</Button>
            <Button onClick={() => {
                setNodes([...nodes, {
                    id: '4',
                    data: { label: 'Chinnu gand' },
                    position: { x: 250, y: 250 },
                }])
            }} >Add</Button>
            <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
                nodeTypes={nodeTypes}
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
}

export default ReactFlowComp;
