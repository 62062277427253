import { Box, Typography } from '@mui/material';
import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Sendtext = ({ data, hitAxios, code, text }) => {
    const codeStyle = {
        ...oneLight,
        fontSize: '10px', // Adjust the value as per your preference
    };

    const codeString = code

    return (
        <div>
            <SyntaxHighlighter language="js" style={codeStyle}>
                {codeString.trim()}
            </SyntaxHighlighter>

            <Box mt={2}>
                <Typography color={'gray'}>
                    {text}
                </Typography>
            </Box>
        </div>
    )
}

export default Sendtext