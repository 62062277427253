import { AddCircle, Article, CloudDownload } from '@mui/icons-material'
import { Button, CardActionArea, Chip, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import { FlowContext } from '../../../context/FlowContext'
import TextFieldNew from '../../../components/TextFieldNew'

const CreateDoc = ({ data, setState, state }) => {
    const FLOW = React.useContext(FlowContext)
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [doc, setDoc] = React.useState({
        caption: "",
        file: ""
    })

    async function returnUrl(media) {
        const fd = new FormData()
        fd.append('file', media)
        const res = await hitAxios({
            path: "/api/user/return_media_url",
            post: true,
            admin: false,
            obj: fd
        })
        if (res.data.success) {
            setDoc({ ...doc, url: res.data.url })
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Article sx={{ color: 'gray' }} />
                    <Typography variant='h6' fontWeight={600} >{data.docMsg}</Typography>
                </Stack>


                {CON.data.loading && <LinearProgress />}

                <CardActionArea component='label' sx={{ borderRadius: 2, bgcolor: '#ECECEC' }}>
                    <input
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 75 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                    alert(data.docAlertMsg);
                                } else {
                                    returnUrl(selectedFile);
                                }
                            }
                        }}
                        type='file'
                        accept='.text, .txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
                        hidden
                    />
                    {doc.url ? <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        <IconButton onClick={() => window.open(doc.url)} >
                            <CloudDownload />
                        </IconButton>
                        <Typography variant='body2' color={'gray'}>{data.download}</Typography>
                    </Stack> :
                        <Stack m={4} alignItems={'center'}>
                            <Typography variant='caption' color='gray' >{data.uploadDoc}</Typography>
                        </Stack>}
                </CardActionArea>


                <TextFieldNew
                    onChange={(e) => setDoc({ ...doc, caption: e.target.value })}
                    placeholder="hello choose one color &#13;&#10;1:- Red &#13;&#10;2:- Green"
                    fullWidth
                    multiline
                    rows={4}
                />

                <Stack flexWrap={'wrap'} direction={'row'} >
                    {state?.options?.map((i, key) => {
                        return (
                            <Chip
                                sx={{ m: 1 }}
                                onDelete={() => {
                                    const newOne = state.options.filter((ii) => ii !== i)
                                    setState({ ...state, options: newOne })
                                }}
                                label={i} key={key} />
                        )
                    })}
                </Stack>

                <TextFieldNew
                    placeholder={data.replyKey}
                    value={state?.option1}
                    onChange={(e) => setState({ ...state, option1: e.target.value })}
                    endIcon={<IconButton disabled={state.option1 ? false : true} onClick={() => {
                        setState({ ...state, options: [...state.options, state.option1], option1: "" })
                    }} >
                        <AddCircle />
                    </IconButton>}
                />


                <Button
                    onClick={() => {
                        FLOW.setNodes([
                            ...FLOW.nodes || [],
                            {
                                id: Date.now().toString(),
                                data: { data: "data", source: false, state },
                                position: {
                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                },
                                type: 'AskQuestionMap',
                                state,
                                nodeType: "QUESTION-DOCUMENT",
                                keyword: state.options,
                                msgContent: {
                                    "type": "document",
                                    "document": {
                                        "link": doc.url,
                                        "caption": doc.caption
                                    }
                                }
                            }
                        ]);
                        setState({ ...state, text: "", dialog: false })
                    }}
                    disabled={doc.caption && state.options.length > 0 ? false : true} variant='contained' sx={{ borderRadius: 2, boxShadow: 0, textTransform: 'none' }} >
                    {data.addThis}
                </Button>
            </Stack>
        </div>
    )
}

export default CreateDoc