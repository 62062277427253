import { Stack } from '@mui/material';
import React, { useState } from 'react';

const ButtonComp = ({ title, startIcon, onClick, idSuffix, padding, fontSize, fullWidth, bgcolor, disabled, color, fontWeight, borderWeight }) => {
    const [isHovered, setHovered] = useState(false);
    const buttonId = `button_${idSuffix}`;
    const buttonStyle = {
        backgroundColor: bgcolor ? bgcolor : '#22a454',
        border: '0 solid #E5E7EB',
        boxSizing: 'border-box',
        color: color || 'white',
        width: fullWidth && "100%",
        display: 'flex',
        fontFamily: 'ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        fontSize: fontSize || '0.85rem',
        justifyContent: 'center',
        lineHeight: '1.75rem',
        padding: padding || '.40rem 1.4rem',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none #000000 solid',
        textDecorationThickness: 'auto',
        maxWidth: '460px',
        transform: 'rotate(-2deg)',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        touchAction: 'manipulation',
        cursor: 'pointer',
        borderRadius: 10,
        fontWeight: fontWeight && fontWeight
    };

    const focusStyle = {
        outline: 0,
    };

    const afterStyle = {
        content: '',
        position: 'absolute',
        border: borderWeight ? `${borderWeight}px solid #000000` : '1px solid #000000',
        bottom: isHovered ? '2px' : '4px',
        left: isHovered ? '2px' : '4px',
        width: 'calc(100% - 1px)',
        height: 'calc(100% - 1px)',
        transition: 'bottom 0.3s ease, left 0.3s ease',
        borderRadius: 10
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <button
            disabled={disabled}
            id={buttonId}
            onClick={onClick}
            className="button-53"
            role="button"
            style={buttonStyle}
            onFocus={() => { }}
            onBlur={() => { }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Stack alignItems={'center'} direction={'row'} justifyContent={'center'}>
                {startIcon}&nbsp;
                {title || "Button"}
            </Stack>
            <div style={afterStyle}></div>
        </button>
    );
};

export default ButtonComp;
