import React from 'react'
import AddCampaign from './AddCampaign'
import AddBroadDialog from './AddBroadDialog'
import BroadCastList from './BroadCastList'
import { GlobalContext } from '../../../context/GlobalContext'

const SendBroadcast = ({ setMenu, menu, data, screenWidth }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [broadList, setBroadList] = React.useState([])
    const [state, setState] = React.useState({
        dialogAdd: false
    })

    async function getBroadList() {
        const res = await hitAxios({
            path: "/api/broadcast/get_broadcast",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setBroadList(res.data.data)
        }
    }

    React.useEffect(() => {
        getBroadList()
    }, [])

    return (
        <div>
            <AddCampaign
                state={state}
                setState={setState}
                screenWidth={screenWidth}
                data={data}
            />
            <AddBroadDialog
                broadList={broadList}
                getBroadList={getBroadList}
                data={data}
                setState={setState}
                state={state}
            />

            <BroadCastList
                broadList={broadList}
                getBroadList={getBroadList}
                data={data}
                setState={setState}
                state={state}
            />
        </div>
    )
}

export default SendBroadcast