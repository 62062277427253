import { Stack } from '@mui/material'
import React from 'react'
import FlowList from './FlowList';
import FlowListComp from './FlowListComp';
import { GlobalContext } from '../../context/GlobalContext'
import { FlowContext } from '../../context/FlowContext';

const ChatFlow = ({ data }) => {
    const FLOW = React.useContext(FlowContext)
    const [chatFlow, setChatFlow] = React.useState([])
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        drawer: false,
        dialogAdd: false
    })

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    async function saveFlow() {
        const res = await hitAxios({
            path: "/api/chat_flow/add_new",
            post: true,
            admin: false,
            obj: { nodes: FLOW.nodes, edges: FLOW.edges, title: FLOW.data?.title, flowId: FLOW.data?.flowId }
        })
        if (res.data.success) {
            // const initialNodes = [
            //     {
            //         id: '1',
            //         data: { data: "data", source: true },
            //         position: { x: 100, y: 100 },
            //         type: 'SingleMessageInput',
            //         msgContent: {
            //             "type": "text",
            //             "text": {
            //                 "preview_url": true,
            //                 "body": ""
            //             }
            //         },
            //         state: {
            //             "dialog": true,
            //             "dialogType": "TEXT",
            //             "options": [
            //                 "asdsa"
            //             ],
            //             "text": "",
            //             "option1": ""
            //         },
            //     },
            // ];
            FLOW.setNodes([])
            FLOW.setEdges([])
            FLOW.setData({ ...FLOW.data, title: "Untitled" })
            getFlows()
        }
    }

    async function getFlows() {
        const res = await hitAxios({
            path: "/api/chat_flow/get_mine",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setChatFlow(res.data.data)
        }
    }

    React.useEffect(() => {
        getFlows()
    }, [])

    return (
        <div>
            <Stack direction={'row'}>
                <FlowList
                    FLOW={FLOW}
                    chatFlow={chatFlow}
                    hitAxios={hitAxios}
                    saveFlow={saveFlow}
                    getFlows={getFlows}
                    state={state} setState={setState} screenWidth={screenWidth} data={data} />
                <Stack width={'100%'} direction={'column'}>

                    <FlowListComp
                        saveFlow={saveFlow}
                        getFlows={getFlows}
                        screenWidth={screenWidth}
                        data={data} state={state} setState={setState} />
                </Stack>
            </Stack>
        </div>
    )
}

export default ChatFlow