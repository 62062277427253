import { Autocomplete, Box, Button, CardActionArea, Dialog, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import CloseBtn from '../../../../context/CloseBtn'
import { GlobalContext } from '../../../../context/GlobalContext'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { Quickreply, Send } from '@mui/icons-material'

const SendBottomQuick = ({ data, state, setState, INBOX }) => {
    const [templets, setTemplets] = React.useState("")
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)

    async function getTemplets() {
        const res = await hitAxios({
            path: "/api/templet/get_templets",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setTemplets(res.data.data)
        }
    }

    React.useEffect(() => {
        getTemplets()
    }, [])

    async function sendTemplet() {
        const res = await hitAxios({
            path: "/api/inbox/send_templet",
            post: true,
            admin: false,
            obj: {
                content: state?.content,
                toNumber: INBOX?.data?.openedChat?.sender_mobile,
                toName: INBOX?.data?.openedChat?.sender_name,
                chatId: INBOX?.data?.openedChat?.chat_id,
                msgType: state?.type?.toLowerCase()
            }
        })
        if (res.data.success) {
            setState({ ...state, quickDialog: false })
        }
    }

    return (
        <Dialog
            fullWidth
            PaperProps={{
                style: { borderRadius: 10 }
            }}
            key="7"
            open={state.quickDialog}
            onClose={() => setState({ ...state, quickDialog: false })}
        >
            <Box p={2} >
                <Stack mb={2} direction={'row'} justifyContent={'flex-end'}>
                    <CloseBtn
                        onClick={() => setState({ ...state, quickDialog: false })}
                    />
                </Stack>
                <Box mt={2}>
                    {templets ? <Stack direction={'column'} spacing={2}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={templets?.map((i) => ({
                                label: i?.title,
                                value: i?.id,
                                id: i.id,
                                title: i?.title,
                                type: i?.type,
                                createdAt: i?.createdAt,
                                content: i?.content
                            }))}
                            isOptionEqualToValue={(option, value) => option.value === value}
                            renderOption={(props, option) => (
                                <CardActionArea onClick={() => {
                                    console.log({
                                        con: JSON.parse(option?.content),
                                        type: option?.type,
                                        option
                                    })
                                    setState({ ...state, content: JSON.parse(option?.content), type: option?.type })
                                }} >
                                    <Box {...props} >
                                        <Stack direction={'column'}>
                                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                <Typography variant='body2'>{option.title}</Typography>
                                                <Typography variant='caption' color={'gray'}>
                                                    {moment(option.createdAt).fromNow()}
                                                </Typography>
                                            </Stack>
                                            <Typography color={'gray'} variant='caption' >{option.type}</Typography>
                                        </Stack>
                                    </Box>
                                </CardActionArea>
                            )}
                            renderInput={(params) => <TextField
                                fullWidth
                                {...params} label={data.templets} />}
                        />

                        <LoadingButton
                            disabled={state.content ? false : true}
                            loading={CON.data?.loading}
                            onClick={sendTemplet}
                            startIcon={<Send />}
                            variant='contained'
                            sx={{ boxShadow: 0, borderRadius: 2 }}
                        >{data.send}</LoadingButton>
                    </Stack> :
                        <LinearProgress />}
                </Box>
            </Box>
        </Dialog>
    )
}

export default SendBottomQuick