import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { TranslateContext } from '../../context/TranslateContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { GlobalContext } from '../../context/GlobalContext';
import moment from 'moment';

const Orders = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [orders, setOrders] = React.useState([])

    async function getOrders() {
        const res = await hitAxios({
            path: "/api/admin/get_orders",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setOrders(res.data.data)
        }
    }

    React.useEffect(() => {
        getOrders()
    }, [])

    return (
        <div>
            <Box mt={2} >
                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={'bold'}
                            variant='h6'>{data.order}</Typography>
                        <Typography
                            color={'gray'}
                            variant='caption'>{data.ordersDes}</Typography>
                    </Stack>
                </Stack>

                <Box mt={4} borderRadius={4} bgcolor={'action.hover'}>
                    {orders ?
                        <Box height='80vh'>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={[...orders].reverse()}
                                columns={[
                                    {
                                        headerName: data.payType, field: 'payment_mode', flex: 1
                                    },
                                    {
                                        headerName: data.amount, field: 'amount', flex: 1
                                    },
                                    {
                                        headerName: data.status, field: 'data', flex: 1, renderCell: row => {
                                            return (
                                                row?.row?.data?.startsWith("STRIPE") ? data.pending : data.success
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.name, field: 'name', flex: 1, renderCell: row => {
                                            return (
                                                row.row?.name
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.email, field: 'email', flex: 1, renderCell: row => {
                                            return (
                                                row.row?.email
                                            )
                                        }
                                    },
                                    {
                                        headerName: data.date, field: 'createdAt', flex: 1, renderCell: row => {
                                            return (
                                                moment(row?.row?.createdAt).format("DD/MM/YY | hh:mmA")
                                            )
                                        }
                                    }
                                ]}
                                pageSize={50}
                                rowsPerPageOptions={[50]}
                                checkboxSelection={false}
                                // components={{
                                //     Toolbar: CustomToolbar
                                // }}
                                sx={{
                                    boxShadow: 0,
                                    borderRadius: 4,
                                    border: 0,
                                    fontSize: 13,
                                    padding: 2
                                }}
                            />
                        </Box> : ""
                    }
                </Box>
            </Box>
        </div>
    )
}

export default Orders