import { Box, IconButton, Stack, Drawer, Typography, CardMedia } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../components/TextFieldNew'
import ButtonComp from '../../context/ButtonComp'
import { AddCircle, ContactPage, Menu, MoreHoriz } from '@mui/icons-material'

const AddPhoneBook = ({ data, setState, state, screenWidth, hitAxios, getPhoneBooks }) => {
    const [states, setStates] = React.useState({

    })

    async function hangleAdd() {
        const res = await hitAxios({
            path: "/api/phonebook/add",
            post: true,
            admin: false,
            obj: states
        })
        if (res.data.success) {
            setStates({ ...states, name: "" })
            getPhoneBooks()
        }
    }

    return (
        <Box p={3}>

            <Box mb={3}>
                <Box display="flex" alignItems="center">
                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginRight={2} />
                    <Typography variant="body2" fontWeight={500} color={'gray'} >{data.addPhonebook}</Typography>
                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginLeft={2} />
                </Box>
            </Box>

            <Stack direction={'row'} alignItems={'flex-end'} spacing={2}>
                {screenWidth < 899 &&
                    <IconButton onClick={() => setState({ ...state, drawer: !state.drawer })} >
                        <Menu sx={{ color: (t) => t.palette.primary.main }} />
                    </IconButton>}

                <TextFieldNew
                    startIcon={<ContactPage sx={{ height: 20, width: 20, color: (t) => t.palette.primary.main }} />}
                    value={states.name}
                    onChange={(e) => setStates({ ...states, name: e.target.value })}
                    label={data.enterPhoneBookName} fullWidth />
                <ButtonComp
                    onClick={hangleAdd}
                    startIcon={<AddCircle sx={{ height: 20, width: 20 }} />}
                    title={data.addPhoneBook} />
            </Stack>

            <Box mt={2}>
                <Box display="flex" alignItems="center">
                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginRight={2} />
                    <Typography variant="body2" fontWeight={500} color={'gray'} >{data.contacts}</Typography>
                    <Box flex="1" borderBottom={1} borderColor="#E3E3E3" marginLeft={2} />
                </Box>

            </Box>

        </Box>
    )
}

export default AddPhoneBook