import { Add, AddCircle, ContactPage, DeleteOutline } from '@mui/icons-material'
import { Avatar, Box, CardMedia, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import CloseBtn from '../../context/CloseBtn'

const phoneBookList = ({ data, state, setState, screenWidth, phoneBookData, hitAxios, getPhoneBooks, }) => {

    async function delPhone(id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/phonebook/del_phonebook",
                post: true,
                admin: false,
                obj: { id }
            })
            if (res.data.success) {
                getPhoneBooks()
            }
        }
    }
    return (
        <div>
            {screenWidth > 899 ? <Box width={250} minHeight={'100vh'} overflow={'auto'} bgcolor={'white'}>
                <Box pr={4} pl={4} pt={4}>
                    <Stack alignItems={'center'} direction={'column'} spacing={2}>
                        <CardMedia
                            component={'img'}
                            sx={{ height: "100%", width: 250 }}
                            src='/assets/phonebook_one.svg'
                        />
                        {/* <ContactPage sx={{ height: 50, width: 50, color: data.color_theme }} /> */}
                        <Typography fontWeight={600} align='center' color={'gray'} >{data.yourPhoneBooks}</Typography>
                    </Stack>
                </Box>

                <Box p={2} >
                    <Divider />
                </Box>

                <Stack pr={2} pl={2} spacing={2} direction={'column'} >
                    {phoneBookData.map((i, key) => {
                        return (
                            <Stack key={key} justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                                <Stack alignItems={'flex-start'} direction={'row'} spacing={1}>
                                    <Tooltip title={data.clickHereToAddCOntacts} >
                                        <IconButton
                                            size='small'
                                            onClick={() => setState({ ...state, dialogContact: true, dialogContactData: i })}
                                            color='info' >
                                            <AddCircle sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Stack direction={'column'}>
                                        <Typography variant='body2' >{i?.name}</Typography>
                                        <Typography variant='caption' color={'gray'} >{moment(i?.createdAt).fromNow()}</Typography>
                                    </Stack>
                                </Stack>

                                <CloseBtn onClick={() => delPhone(i.id)} />
                            </Stack>
                        )
                    })}
                </Stack>
            </Box> :
                <Drawer key="7" onClose={() => setState({ ...state, drawer: false })} open={state.drawer && screenWidth < 899}>
                    <Box width={250} minHeight={'100vh'} bgcolor={'white'}>
                        <Box pr={4} pl={4} pt={4}>
                            <Stack alignItems={'center'} direction={'column'} spacing={2}>
                                <CardMedia
                                    component={'img'}
                                    sx={{ height: "100%", width: "100%" }}
                                    src='/assets/phonebook_one.svg'
                                />
                                {/* <ContactPage sx={{ height: 50, width: 50, color: data.color_theme }} /> */}
                                <Typography fontWeight={600} align='center' color={'gray'} >{data.yourPhoneBooks}</Typography>
                            </Stack>
                        </Box>

                        <Box p={2} >
                            <Divider />
                        </Box>

                        <Stack pr={2} pl={2} spacing={2} direction={'column'} >
                            {phoneBookData.map((i, key) => {
                                return (
                                    <Stack key={key} justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}>
                                        <Stack alignItems={'flex-start'} direction={'row'} spacing={1}>
                                            <Tooltip title={data.clickHereToAddCOntacts} >
                                                <IconButton
                                                    onClick={() => setState({ ...state, dialogContact: true, dialogContactData: i })}
                                                    color='info' >
                                                    <AddCircle />
                                                </IconButton>
                                            </Tooltip>
                                            <Stack direction={'column'}>
                                                <Typography variant='body2' >{i?.name}</Typography>
                                                <Typography variant='caption' color={'gray'} >{moment(i?.createdAt).fromNow()}</Typography>
                                            </Stack>
                                        </Stack>


                                        <CloseBtn onClick={() => delPhone(i.id)} />
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Box>
                </Drawer>}

        </div >
    )
}

export default phoneBookList