import { Box, Button, Chip, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import { KeyboardDoubleArrowLeft, Menu, Save, SaveAlt, SystemUpdateAlt } from '@mui/icons-material'
import React from 'react'
import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
    MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import TempletsComp from './TempletsComp';
import { FlowContext } from '../../context/FlowContext';

import SingleMessageInput from './templets/SingleMessageInput'

import AskQuestionMap from './templets/AskQuestionMap'
import { GlobalContext } from '../../context/GlobalContext';

const nodeTypes = {
    SingleMessageInput: SingleMessageInput,
    AskQuestionMap: AskQuestionMap
}

const FlowListComp = ({ screenWidth, data, state, setState, saveFlow, getFlows }) => {
    const FLOW = React.useContext(FlowContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [states, setStates] = React.useState({
        darwer: false
    })

    const onNodesChange = React.useCallback(
        (changes) => {
            const id = changes[0]?.id
            if (id === "1" && changes[0]?.type === "remove") {
                // console.log("returned")
                return
            }
            // console.log("not returned")
            FLOW.setNodes((nds) => applyNodeChanges(changes, nds))
        },
        [],
    );

    const onEdgesChange = React.useCallback(
        (changes) => FLOW.setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onConnect = React.useCallback(
        (params) => FLOW.setEdges((eds) => addEdge(params, eds)),
        // (params) => setEdges((eds) => addEdge(params, eds)),
        [],
    );


    return (
        <div>
            <Box
                sx={{
                    width: screenWidth > 899 ? screenWidth - 250 : "100%"
                }} >
                <Box height={'83vh'} bgcolor={'white'} position={'relative'} p={2} borderRadius={3} m={2}>

                    <ReactFlow
                        nodes={FLOW.nodes}
                        onNodesChange={onNodesChange}
                        edges={FLOW.edges}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                    >
                        <MiniMap style={{ height: 100, width: 150 }} zoomable pannable />
                        <Background />
                        <Controls />
                    </ReactFlow>

                    <TempletsComp data={data} states={states} setStates={setStates} />

                    <Stack p={2} position={'absolute'} top={0} right={0} left={0} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                        {screenWidth < 899 &&
                            <IconButton onClick={() => setState({ ...state, drawer: !state.drawer })} >
                                <Menu />
                            </IconButton>}



                        <Stack width={'100%'} direction={'row'} justifyContent={'flex-end'}>
                            <Stack direction={'row'} spacing={2}>

                                <input
                                    onChange={(e) => FLOW.setData({ ...FLOW.data, title: e.target.value })}
                                    type="text"
                                    value={FLOW.data?.title}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        fontSize: 17,
                                        backgroundColor: 'rgba(255, 255, 255, 0.33)',
                                        borderRadius: 10,
                                        padding: "0px 20px 0px 20px"
                                    }}
                                />

                                {/* <Tooltip title={data.exportTemplet} >
                                    <IconButton color='secondary' size='large'>
                                        <SystemUpdateAlt />
                                    </IconButton>
                                </Tooltip> */}

                                <Tooltip title={data.saveTemplet} >
                                    <IconButton
                                        onClick={saveFlow}
                                        color='success' size='large'>
                                        <Save />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={data.openTemplets} >
                                    <IconButton
                                        onClick={() => setStates({ ...states, drawer: true })}
                                        color='info' size='large'>
                                        <KeyboardDoubleArrowLeft />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </div>
    )
}

export default FlowListComp