import { Button, CardActionArea, CardMedia, Chip, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import TextFieldNew from '../../../components/TextFieldNew'
import { GlobalContext } from '../../../context/GlobalContext'
import { FlowContext } from '../../../context/FlowContext'
import { AddCircle, Image } from '@mui/icons-material'

const CreateImage = ({ data, state, setState }) => {
    const FLOW = React.useContext(FlowContext)
    const CON = React.useContext(GlobalContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [img, setImg] = React.useState({
        caption: "",
        file: ""
    })

    async function returnUrl(media) {
        const fd = new FormData()
        fd.append('file', media)
        const res = await hitAxios({
            path: "/api/user/return_media_url",
            post: true,
            admin: false,
            obj: fd
        })
        if (res.data.success) {
            setImg({ ...img, url: res.data.url })
        }
    }

    return (
        <div>
            <Stack direction={'column'} spacing={2}>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Image sx={{ color: 'gray' }} />
                    <Typography variant='h6' fontWeight={600} >{data.askByImage}</Typography>
                </Stack>

                {CON.data.loading && <LinearProgress />}
                <CardActionArea component='label' sx={{ borderRadius: 2, bgcolor: '#ECECEC' }}>
                    <input
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                const selectedFile = e.target.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                                if (selectedFile.size > maxSize) {
                                    alert(data.imgSizeAlert);
                                } else {
                                    returnUrl(selectedFile);
                                }
                            }
                        }}
                        type='file'
                        accept='.jpeg, .png, .jpg, .jfif, .pjpeg, .webp'
                        hidden
                    />
                    {img.url ? <CardMedia
                        sx={{ borderRadius: 2 }}
                        src={img.url} component="img" /> :
                        <Stack m={4} alignItems={'center'}>
                            <Typography variant='caption' color='gray' >{data.uploadIMage}</Typography>
                        </Stack>}
                </CardActionArea>

                <TextFieldNew
                    onChange={(e) => setImg({ ...img, caption: e.target.value })}
                    label={data.caption}
                    fullWidth
                    multiline
                    rows={4}
                />

                <Stack flexWrap={'wrap'} direction={'row'} >
                    {state?.options?.map((i, key) => {
                        return (
                            <Chip
                                sx={{ m: 1 }}
                                onDelete={() => {
                                    const newOne = state.options.filter((ii) => ii !== i)
                                    setState({ ...state, options: newOne })
                                }}
                                label={i} key={key} />
                        )
                    })}
                </Stack>

                <TextFieldNew
                    placeholder={data.replyKey}
                    value={state?.option1}
                    onChange={(e) => setState({ ...state, option1: e.target.value })}
                    endIcon={<IconButton disabled={state.option1 ? false : true} onClick={() => {
                        setState({ ...state, options: [...state.options, state.option1], option1: "" })
                    }} >
                        <AddCircle />
                    </IconButton>}
                />


                <Button
                    disabled={img.caption && img.url && state?.options?.length > 0 ? false : true}
                    onClick={() => {
                        FLOW.setNodes([
                            ...FLOW.nodes || [],
                            {
                                id: Date.now().toString(),
                                data: { data: "data", source: false, state },
                                position: {
                                    x: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.x + 100 || 300 : 300,
                                    y: (FLOW.nodes && FLOW.nodes.length > 0) ? FLOW.nodes[FLOW.nodes.length - 1]?.position?.y || 300 : 300,
                                },
                                type: 'AskQuestionMap',
                                state,
                                nodeType: "QUESTION-IMAGE",
                                keyword: [img.caption] || [],
                                msgContent: {
                                    "type": "image",
                                    "image": {
                                        "link": img.url,
                                        "caption": img.caption
                                    }
                                }
                            }
                        ]);
                        setState({ ...state, text: "", dialog: false })
                    }}
                    sx={{ textTransform: 'none', borderRadius: 2, boxShadow: 0 }} variant='contained' >{data.addThis}</Button>
            </Stack>
        </div>
    )
}

export default CreateImage