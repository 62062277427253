import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function getTimePercentage(timeString) {
  if (typeof (timeString) !== 'function') return
  // Parse the time string into hours and minutes
  const [hours, minutes] = timeString?.split(':').map(Number);

  // Convert the time to total minutes since midnight
  const totalMinutes = hours * 60 + minutes;

  // Calculate the total minutes in a day (24 hours * 60 minutes)
  const totalMinutesInDay = 24 * 60;

  // Calculate the percentage of the day
  const percentage = (totalMinutes / totalMinutesInDay) * 100;

  return percentage
}


export default function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size="3.5rem" value={typeof (props.value) !== 'Expired' ? getTimePercentage(props?.value) : 0}
        variant="determinate" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: props.value === props?.data?.expired ? 'rgba(255, 0, 0, 0.05)' : 'rgba(0, 255, 53, 0.05)',
          borderRadius: "50%"
        }}
      >
        <Typography color={props.value === props?.data?.expired ? "red" : "green"} fontWeight={500} fontSize={12} component="div" >
          {`${props.value}`}
        </Typography>
      </Box>
    </Box>
  );
}


CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
