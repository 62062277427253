import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import ButtonComp from '../../context/ButtonComp'
import { GlobalContext } from '../../context/GlobalContext'
import { TranslateContext } from '../../context/TranslateContext'
import UsersList from './UsersList'

const ManageUsers = () => {
    const { data } = React.useContext(TranslateContext)
    const { hitAxios } = React.useContext(GlobalContext)
    const [users, setUsers] = React.useState([])
    const [state, setState] = React.useState({
        dialogEdit: false,
        dialogEditData: {}
    })

    async function getUsers() {
        const res = await hitAxios({
            path: "/api/admin/get_users",
            post: false,
            admin: true,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setUsers(res.data.data)
        }
    }

    React.useEffect(() => {
        getUsers()
    }, [])

    return (
        <div>
            <Box mt={2} >
                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={'bold'}
                            variant='h6'>{data.manageUsers}</Typography>
                        <Typography
                            color={'gray'}
                            variant='caption'>{data.manageUserDes}</Typography>
                    </Stack>
                </Stack>

                <UsersList
                    getUsers={getUsers}
                    state={state}
                    setState={setState}
                    data={data}
                    users={users}
                />
            </Box>
        </div>
    )
}

export default ManageUsers