import React from 'react'
import { Box, Button, Container, Divider, IconButton, ImageList, Stack, Typography } from '@mui/material'
import { TranslateContext } from '../../context/TranslateContext'
import { GlobalContext } from '../../context/GlobalContext'
import TextFieldNew from '../../components/TextFieldNew'
import { DeleteOutline } from '@mui/icons-material'

const Testimonial = () => {
    const { data } = React.useContext(TranslateContext)
    const [testi, setTesti] = React.useState([])
    const { hitAxios } = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
    })
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    async function getTesti() {
        const res = await hitAxios({
            path: "/api/admin/get_testi",
            post: false,
            admin: false,
            token_user: ""
        })
        if (res.data.success) {
            setTesti(res.data.data)
            setState({ title: "", description: "", reviewer_name: "", reviewer_position: "" })
        }
    }

    React.useEffect(() => {
        getTesti()
    }, [])

    async function addTestimonial() {
        const res = await hitAxios({
            path: "/api/admin/add_testimonial",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            getTesti()
        }
    }

    async function delTesti(id) {
        if (window.confirm(data.AUS)) {
            const res = await hitAxios({
                path: "/api/admin/del_testi",
                post: true,
                admin: true,
                obj: { id }
            })
            if (res.data.success) {
                getTesti()
            }
        }
    }

    return (
        <div>
            <Box mt={2} >
                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                        <Typography
                            fontWeight={'bold'}
                            variant='h6'>{data.testimonial}</Typography>
                        <Typography
                            color={'gray'}
                            variant='caption'>{data.addDeleteTestimonial}</Typography>
                    </Stack>
                </Stack>

                <Box mt={4}>
                    <Stack direction={'column'} spacing={2}>
                        <TextFieldNew
                            onChange={(e) => setState({ ...state, title: e.target.value })}
                            value={state.title}
                            label={data.title}
                        />
                        <TextFieldNew
                            onChange={(e) => setState({ ...state, description: e.target.value })}
                            value={state.description}
                            multiline
                            rows={2}
                            label={data.description}
                        />
                        <Stack direction={'row'} spacing={2}>
                            <TextFieldNew
                                onChange={(e) => setState({ ...state, reviewer_name: e.target.value })}
                                value={state.reviewer_name}
                                fullWidth
                                label={data.reviewerName}
                            />
                            <TextFieldNew
                                onChange={(e) => setState({ ...state, reviewer_position: e.target.value })}
                                value={state.reviewer_position}
                                fullWidth
                                label={data.reviewerPosition}
                            />
                        </Stack>

                        <Button
                            onClick={addTestimonial}
                            variant='contained' sx={{ boxShadow: 0, borderRadius: 2 }} >
                            {data.add}
                        </Button>

                        <Divider />

                        <Box borderRadius={4} pb={screenWidth > 499 ? 6 : 3} pt={screenWidth > 499 ? 6 : 3} bgcolor={'action.hover'} >
                            <Container maxWidth='lg'>
                                <ImageList
                                    sx={{
                                        gridAutoFlow: "column",
                                        gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                                        gridAutoColumns: "minmax(160px, 1fr)",
                                        msOverflowStyle: 'none',
                                        overflowX: 'scroll',
                                        '::-webkit-scrollbar': {
                                            width: '10px'
                                        }
                                    }}
                                >
                                    <Stack spacing={4} direction={'row'}>
                                        {testi?.map((i, key) => {
                                            return (
                                                <Box
                                                    key={key}
                                                    sx={{ borderRadius: 3, borderLeft: 4, borderTop: 4 }}
                                                    minWidth={screenWidth > 499 ? '27vw' : '50vw'} borderRadius={3} bgcolor={'white'} p={3}>
                                                    <Stack direction={'column'} spacing={2}>

                                                        <Stack direction={'row'} justifyContent={'flex-end'}>
                                                            <IconButton
                                                                onClick={() => delTesti(i?.id)}
                                                                color='error' >
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        </Stack>

                                                        <Typography variant='h6' >
                                                            {i?.title}
                                                        </Typography>
                                                        <Typography fontWeight={500} >
                                                            {i?.description}
                                                        </Typography>

                                                        <Stack direction={'column'}>
                                                            <Typography fontWeight={600} variant='body2'>
                                                                {i?.reviewer_name}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {i?.reviewer_position}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            )
                                        })}
                                    </Stack>
                                </ImageList>
                            </Container>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </div>
    )
}

export default Testimonial