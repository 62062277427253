import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { HomeOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { TranslateContext } from './context/TranslateContext'

const Error404 = () => {
    const { data } = React.useContext(TranslateContext)
    return (
        <Box minHeight={'100vh'} bgcolor={'#3498DB'}>
            <Container maxWidth='lg'>
                <Box pt={5}>
                    <Typography variant='h1' fontWeight={'bold'} >:(</Typography>
                    <Box mt={4}>
                        <Typography>{data.page404Error}</Typography>
                    </Box>
                    <Box mt={4}>
                        <Button
                            component={Link}
                            to="/"
                            startIcon={<HomeOutlined />}
                            variant='contained'
                            size='large'
                            color='inherit'
                            sx={{ borderRadius: 2, textTransform: 'none', boxShadow: 0 }}
                        >{data.backToHome}</Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Error404