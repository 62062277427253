import { Email, Key, Password, Translate, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Typography, Stack, IconButton, Grid, Container, TextField, Button } from '@mui/material'
import React from 'react'
import TextFieldNew from '../components/TextFieldNew'
import { GlobalContext } from '../context/GlobalContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../frontend/components/Header'
import { TranslateContext } from '../context/TranslateContext'
import Footer from '../frontend/components/Footer'
import AdminLoginRecovery from './AdminLoginRecovery'

const AdminLogin = () => {
    const [state, setState] = React.useState({
        showPass: false
    })
    const { hitAxios } = React.useContext(GlobalContext)
    const CON = React.useContext(GlobalContext)
    const history = useHistory()
    const { data } = React.useContext(TranslateContext)
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);


    async function login() {
        const res = await hitAxios({
            path: "/api/admin/login",
            post: true,
            admin: true,
            obj: state
        })
        if (res.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_admin", res.data.token)
            history.push('/admin')
            return
        }
    }
    return (
        <Box
            sx={{
                background: "#f3fff7",
                background: "linear-gradient(to bottom right, #f3fff7, #fbfbfc)"
            }}
            minHeight={'100vh'} bgcolor={'#f1f1f1'} >

            <Header
                screenWidth={screenWidth}
                data={data}
            />

            <Box>
                <Container maxWidth='lg'>
                    <Grid container minHeight={'90vh'} alignItems={'center'} justifyContent={'center'}>
                        <Grid xs={12} sm={6} lg={4} item>
                            <Box borderRadius={4} border={0.5} borderColor={'#E1E1E1'} bgcolor={'white'} p={4}>
                                <Stack direction={'column'} spacing={3}>
                                    <Typography fontWeight={'bold'} >Hello Admin 👋🏻</Typography>

                                    <TextFieldNew
                                        type={'email'}
                                        startIcon={<Email sx={{ height: 20, width: 20 }} />}
                                        onChange={(e) => setState({ ...state, email: e.target.value })}
                                        fullWidth
                                        label="Enter your email"
                                    />

                                    <TextFieldNew
                                        endIcon={<IconButton onClick={() => setState({ ...state, showPass: !state.showPass })} >
                                            {state.showPass ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>}
                                        type={state.showPass ? "text" : "password"}
                                        startIcon={<Password sx={{ height: 20, width: 20 }} />}
                                        onChange={(e) => setState({ ...state, password: e.target.value })}
                                        fullWidth
                                        label="Enter your password"
                                    />

                                    <Button
                                        onClick={login}
                                        startIcon={<Key />}
                                        sx={{ borderRadius: 2, boxShadow: 0, textTransform: 'none' }} variant='contained' >
                                        Login
                                    </Button>

                                    <Stack direction={'row'} justifyContent={'flex-end'}>
                                        <AdminLoginRecovery
                                            CON={CON}
                                            data={data}
                                            hitAxios={hitAxios}
                                        />
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Footer
                screenWidth={screenWidth}
                data={data}
            />
        </Box>
    )
}

export default AdminLogin