import React from 'react'
import PhoneBookList from './PhoneBookList'
import { Box, Grid, Stack } from '@mui/material'
import AddPhoneBook from './AddPhoneBook'
import { GlobalContext } from '../../context/GlobalContext'
import AddContacts from './AddContacts'
import ContactList from './ContactList'

const PhoneBook = ({ data }) => {
    const { hitAxios } = React.useContext(GlobalContext)
    const [contactData, setContactData] = React.useState([])
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [phoneBookData, setPhoneBookData] = React.useState([])
    const [state, setState] = React.useState({
        drawer: false,
        dialogContact: false,
        dialogContactData: {}
    })

    async function getContacts() {
        const res = await hitAxios({
            path: "/api/phonebook/get_uid_contacts",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setContactData(res.data.data)
        }
    }

    React.useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    async function getPhoneBooks() {
        const res = await hitAxios({
            path: "/api/phonebook/get_by_uid",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
        })
        if (res.data.success) {
            setPhoneBookData(res.data.data)
        }
    }

    React.useEffect(() => {
        getContacts()
        getPhoneBooks()
    }, [])

    return (
        <div>
            <AddContacts getContacts={getContacts} screenWidth={screenWidth} getPhoneBooks={getPhoneBooks} hitAxios={hitAxios} data={data} state={state} setState={setState} />
            <Stack direction={'row'}>
                <PhoneBookList screenWidth={screenWidth} getPhoneBooks={getPhoneBooks} hitAxios={hitAxios} phoneBookData={phoneBookData} state={state} setState={setState} data={data} />
                <Stack width={'100%'} direction={'column'}>
                    <AddPhoneBook
                        screenWidth={screenWidth} getPhoneBooks={getPhoneBooks} hitAxios={hitAxios} data={data} state={state} setState={setState} />

                    <Box sx={{ width: screenWidth > 899 ? screenWidth - 250 : "100%" }}>
                        <ContactList
                            getContacts={getContacts}
                            setContactData={setContactData}
                            contactData={contactData}
                            screenWidth={screenWidth} getPhoneBooks={getPhoneBooks} hitAxios={hitAxios} data={data} state={state} setState={setState} />
                    </Box>
                </Stack>
            </Stack>
        </div >
    )
}

export default PhoneBook